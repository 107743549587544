<h4 class="ion-margin">{{el.text}}</h4>
<ion-range [pin]="true" [snaps]="true" [ticks]="true" [min]="el.config.min" [max]="el.config.max" [step]="1" [(ngModel)]="el.value" mode="ios"></ion-range>
<ion-row class="ion-justify-content-between">
  <ion-col size="4">
    <span>{{el.config['minText_' + lS.locale] || el.config.minText}}</span>
  </ion-col>
  <ion-col size="4" class="ion-text-end">
    <span>{{el.config['maxText_' + lS.locale] || el.config.maxText}}</span>
  </ion-col>
</ion-row>
<!--
<ion-row class="ion-no-padding">
  <ion-col *ngFor="let tick of rangeArray; first as isFirst; last as isLast;" (click)="el.value = tick;" class="ion-text-center ion-no-padding">
    <ion-chip *ngIf="isFirst" [outline]="(el.value != tick)">
      <ion-label>{{el.config.minText}}</ion-label>
    </ion-chip>
    <ion-chip *ngIf="isLast" [outline]="(el.value != tick)">
      <ion-label>{{el.config.maxText}}</ion-label>
    </ion-chip>
    <ion-chip *ngIf="!isFirst && !isLast" [outline]="(el.value != tick)">
      <ion-label>{{tick}}</ion-label>
    </ion-chip>
  </ion-col>
</ion-row>
-->