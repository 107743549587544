import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-string-field-element',
  templateUrl: './string-field-element.component.html',
  styleUrls: ['./string-field-element.component.scss'],
})
export class StringFieldElementComponent  implements OnInit {

  @Input() el: OHNElement;

  validityRules : any[];

  validInput : boolean = true;

  constructor(
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
    this.el.value = this.el.value || null;
    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    this.el.valid = this.validityRules.length > 0 ? false : true;
  }

  changed() {
    this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
    if (this.el.config.validateAsPhoneNumber) {
      this.validInput = this.el.value == "" ? true : this.checkPhoneNumberValidity();
    }
  }

  checkPhoneNumberValidity() {

    let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return re.test(this.el.value);
  }

}
