import { Component, OnInit, Input, Output } from '@angular/core';
import { OHNUser, OHNCalendarEvent, OHNMedication, OHNMeeting } from '../../models/ohn-instances';
import { Subject } from 'rxjs';
import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent  implements OnInit {
  
  @Input() selectedDate: Date;
  @Input() selectedDateEvents: (OHNCalendarEvent|OHNMedication|OHNMeeting)[];  
  @Input() calendarSlug : string;
  @Input() user : OHNUser;

  selectedEvent: OHNCalendarEvent|OHNMedication|OHNMeeting;
  isModalOpen: boolean = false;
  inlineCommunicator : Subject<any> = new Subject();

  constructor() { }

  ngOnInit() {
    this.inlineCommunicator.subscribe(request=>{
      DEBUG_MODE && console.log("inlineCommunicator", request);
      switch (request.action) {
        case 'close' : 
          this.setOpen(false);
          break;
      }
    })
  }

  openEventModal(event: OHNCalendarEvent|OHNMedication|OHNMeeting) {
    this.selectedEvent = event;

    this.isModalOpen = true;
/*
    let modalOptions: any;

    if (event.contentObject.isFitbitWorkout) {
      modalOptions = {
        component: BranchedContentRendererComponent,
        componentProps: {
          parentElementSlug : 'chop_fitbit_tutorial',
          user : this.user,
          event : event
        },
        cssClass: 'modal-fullscreen ohn-modal-centered',
        backdropDismiss: false
      };
    } else {
      modalOptions = {
        component: CalendarEventViewPage,
        componentProps: {
          'calendarElement' : this.el,
          'user' : this.user,
          'event' : event
        },
        backdropDismiss: false
      }
    }

    const calendarEventPage = await this.modalController.create(modalOptions);

    calendarEventPage.onDidDismiss().then((data) => {
      this.eventCalendar.loadEvents();
    })

    return await calendarEventPage.present();*/
  }

  setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }

}
