import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNUser } from '../models/ohn-instances';
import { BranchedContentRendererComponent } from '../components/branched-content-renderer/branched-content-renderer.component';
import { OhnApiService } from '../api/ohn-api.service';

import { SharedModule } from '../app.shared.module'

@Injectable({
  providedIn: 'root'
})

export class ActionsProcessorService {

  constructor(
    private modalController: ModalController,
    private ohnApi : OhnApiService
  ) { }

  processActions(actions: any[], extras: any, user: OHNUser){
    actions.forEach((a)=>{
      switch (a.actionName) {
        case "openContentPage":
          this.openContentPage(a.params.pageSlug, user);
          break;
        case "saveStateForElement":
          this.saveStateForElement(a.params.elementSlug, extras, user);
          break;
        
        default:
          break;
      }
    });
  }

  saveStateForElement(elementSlug: string, value: any, user: OHNUser) {
    this.ohnApi.setElementStateSc(elementSlug, {value : value}, user.smart_contract).subscribe(state => {
    });
  }

  async openContentPage(pageSlug: string, user: OHNUser) {
    const newSequnceManagerPage = await this.modalController.create({
     component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : pageSlug,
        user : user
      },
      backdropDismiss: false
    });

    newSequnceManagerPage.onDidDismiss().then((data) => {
    })

    return await newSequnceManagerPage.present();
  }
}
