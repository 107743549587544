import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-workout-event-report',
  templateUrl: './workout-event-report.component.html',
  styleUrls: ['./workout-event-report.component.scss'],
})
export class WorkoutEventReportComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
