import { Injectable } from '@angular/core';
import { OHNLogicNode, OHNLogicTransition, OHNLogicTree } from '../models/ohn-instances';

@Injectable({
  providedIn: 'root'
})
export class OhnLogicService {

  logicTree : OHNLogicTree;
  valueContainer : any;
  currentNode : OHNLogicNode;
  previousNode : OHNLogicNode;
  finalNodeReached : boolean;
  reserveNodeId : string;

  constructor() { }

  init(logicTree: any, valueContainer: any, startNodeId : string) {
    this.logicTree = new OHNLogicTree(logicTree);
    this.valueContainer = valueContainer;
    this.currentNode = this.logicTree.nodes.find(node => node.id === startNodeId);
    this.finalNodeReached = this.currentNode.transitions.length === 0;
    return this.logicTree;
  }

  getNextNode() {
    if (!this.finalNodeReached) {  
      let fulfilledTransitions: any[] = this.processTransitions(this.currentNode.transitions);
      if (fulfilledTransitions.length > 0) {
        this.previousNode = this.currentNode;
        this.currentNode = this.logicTree.nodes.find(node => node.id === fulfilledTransitions[fulfilledTransitions.length - 1].params.nextNode);
        this.currentNode.previousNodeId = this.previousNode.id;
        this.finalNodeReached = this.currentNode.transitions.length === 0 && !this.reserveNodeId;
        return this.currentNode;
      } else if (this.reserveNodeId) {
        this.previousNode = this.currentNode;
        this.currentNode = this.logicTree.nodes.find(node => node.id === this.reserveNodeId);
        this.currentNode.previousNodeId = this.previousNode.id;
        this.reserveNodeId = undefined;
        this.finalNodeReached = this.currentNode.transitions.length === 0;
        return this.currentNode;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  goToNode(nodeId: string) {
    this.currentNode = this.logicTree.nodes.find(node => node.id === nodeId);
  }

  processTransitions(transitions: any[]) {
    let transitionResults : any[] = [];
    transitions.forEach(t => {
      let transitionRes: any = this.processTransition(t);
      if (transitionRes.value) {
        if (transitionRes.params.reserveNodeId) {
          this.reserveNodeId = transitionRes.params.reserveNodeId;
        }
        transitionResults.push(transitionRes);
      }
    });
    return transitionResults;
  }

  processTransition(transition: OHNLogicTransition) {
    let transitionResult : any;
    switch (transition.type) {
      case 'compare':
      case 'calculate':
        transitionResult = {
          params : {
            nextNode : transition.nextNode,
            reserveNodeId : transition.reserveNodeId
          },
          value : this.comparisonResult(transition)
        }
        break;
      case 'noConditions':
        transitionResult = {
          params : {
            nextNode : transition.nextNode,
            reserveNodeId : transition.reserveNodeId
          },
          value : true
        }
        break;
      case 'AND':
        let conjunctionRes = this.processTransitions(transition.transitions);
        transitionResult = {
          params : {
            nextNode : transition.nextNode,
            reserveNodeId : transition.reserveNodeId
          },
          value : conjunctionRes.length == transition.transitions.length
        }
        break;
      case '!AND':
        let conjunctionResNegative = this.processTransitions(transition.transitions);
        transitionResult = {
          params : {
            nextNode : transition.nextNode,
            reserveNodeId : transition.reserveNodeId
          },
          value : conjunctionResNegative.length != transition.transitions.length
        }
        break;
      case 'OR':
        let disjunctionRes = this.processTransitions(transition.transitions);
        transitionResult = {
          params : {
            nextNode : transition.nextNode,
            reserveNodeId : transition.reserveNodeId
          },
          value : disjunctionRes.length > 0
        }
        break;
    }
    return transitionResult;
  }

  comparisonResult(transition: OHNLogicTransition) {
    let result : boolean;
    let compareToValue : any;
    if(transition.type == 'calculate') {
      let formula = transition.formula;
      const found = formula.match(/\^[^\/|\*=]*\^/g);
      found.forEach(x=>{
        const key = x.replace(/\^/g, '');
        const replacementValue = this.valueContainer[key].value || 0;
        formula = formula.replace(x, replacementValue)
      })
      console.log(formula);
      compareToValue = this.getFunctionFromString(formula);
      console.log(compareToValue);
    } else {
      compareToValue = this.valueContainer[transition.compareToNode].value;
    }
    if (compareToValue === undefined) {
      result = true;
    } else {
      switch (transition.operator) {
        case '=':
          result = transition.operand == compareToValue;
          break;
        case '!=':
          result = transition.operand != compareToValue;
          break;
        case '>':
          result = transition.operand > compareToValue;
          break;
        case '<':
          result = transition.operand < compareToValue;
          break;
        case '>=':
          result = transition.operand >= compareToValue;
          break;
        case '<=':
          result = transition.operand <= compareToValue;
          break;
        case 'contains':
          result = compareToValue.indexOf(transition.operand) >= 0;
          break;
        case '!contains':
          result = compareToValue.indexOf(transition.operand) < 0;
          break;
      }
    }
    return result;
  }

  processValidityRules(rules: any[], elementController : string, elementValue : any) {
    let result : boolean = true;

    rules.forEach((r)=>{

      switch(r.ruleType){
        case 'valueNotEmpty' :
          switch (elementController) {
            case 'stringFieldController' :
              if (elementValue === undefined || elementValue === null || elementValue === '') {
                result = false
              }
              break;

            case 'pickManyDefaultController' :
              if (elementValue === undefined || elementValue === null || elementValue.length == 0) {
                result = false
              }
              break;

            default :
              if (elementValue === undefined || elementValue === null) {
                result = false
              }
          }
          break;
      }

    })

    return result;
  }

  getFunctionFromString(stringFunc: string) {
    return new Function('return ' + stringFunc)();
  }

  getValueFromElementContainer(elementSlug: string) {
    return this.valueContainer[elementSlug].value;
  }

  setValueOfElementContainer(elementSlug: string, value: any) {
    if (this.valueContainer[elementSlug]) {
      this.valueContainer[elementSlug].value = value;
    }
  }
}