import { Component, OnInit, Input, Directive } from '@angular/core';
import { OHNElement, OHNUser } from '../../../models/ohn-instances';
import { ActionsProcessorService } from '../../../services/actions-processor.service';
import { Subscription, Subject} from 'rxjs';

@Component({
  selector: 'app-content-raw-element',
  templateUrl: './content-raw-element.component.html',
  styleUrls: ['./content-raw-element.component.scss'],
})
export class ContentRawElementComponent implements OnInit {

  @Input() el: any;
  @Input() viewMode : string;
  @Input() user : OHNUser;

  isLinkOpened: boolean = false;
  loadPageSlug: string;

  pageSubs = new Subscription();
  innerCom : Subject<any> = new Subject();

  constructor(
    private actionsProcessor: ActionsProcessorService
  ) { }

  ngOnInit() {
    this.el.value = this.el.value || this.el.text;
    this.el.config = this.el.config || {};
    this.el.valid = true;

    this.innerCom.subscribe(request=>{
      switch (request.action) {
        case 'close' : 
          this.isLinkOpened = false;
          break;
      }
    });

    this.pageSubs.add(this.innerCom);
    /*
    setTimeout(()=>{
      this.updateLinks();
    }, 200);
    */
  }

  updateLinks(){
    let links: any = document.links;
    for (let i = 0, linksLength = links.length; i < linksLength; i++) {
      links[i].target = '_blank';
      if (links[i].href.indexOf('ohn-action') >= 0) {
        this.processOHNActionLink(links[i]);
      }
    }
  }

  processOHNActionLink(link: any) {
    console.log("processOHNActionLink", link);
    link.addEventListener('click', (e) => {
      e.preventDefault();
      this.actionClick(link.href);
    });
  }

  actionClick(configString: string) {
    console.log("actionClick", configString);
    const configArray = configString.split(':');
    if (configArray[0] && configArray[0] == 'ohn-action') {
      switch (configArray[1]) {
        case 'loadPage':
          this.loadPageSlug = configArray[2];
          this.isLinkOpened = true;
          //this.actionsProcessor.openContentPage(configArray[2], this.user);
          break;
        
        default:
          // code...
          break;
      }
    }
  }

  ngOnDestroy() {
    (this.pageSubs) && this.pageSubs.unsubscribe();
  }
}