import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNLoading, OHNUser, OHNContentItem, OHNContentItemList } from '../../../models/ohn-instances';
import { OhnApiService } from '../../../api/ohn-api.service';
import { Subject} from 'rxjs';
import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.scss'],
})
export class ItemsListComponent  implements OnInit {

  @Input() user: OHNUser;
  @Input() el: OHNElement;
  @Input() comSub : Subject<any>;

  loading : OHNLoading = new OHNLoading();

  itemsList: OHNContentItemList = new OHNContentItemList();

  newItem: OHNContentItem = new OHNContentItem();

  itemSearch: string = '';

  selectedToolsCategory: string = 'other-30706';
  selectedCareTeamCategory: string = 'doctors-and-providers-90939';

  btnState: string = 'Save Edits';

  constructor(    
    public lS: OhnLanguageService,
    private ohnApi: OhnApiService
  ) { }

  ngOnInit() {
    this.loading.type = 'triad';
    this.loading.show = true;
    this.loadList();
  }

  loadList() {
    this.ohnApi.getElementStateSc(this.el.element_slug, this.user.smart_contract).subscribe(state => {   
      if (!state.value) {
          this.loading.show = false;
      } else {
        this.itemsList = JSON.parse(state.value.value);
        this.loading.show = false;
      }
      console.log("currentState", this.itemsList);
    });
  }

  addItem(category?: string) {    
    if (category) {
      this.newItem.category = category;
    }
    this.itemsList.content.push(this.newItem);
      console.log("this.itemsList", this.itemsList);
    this.newItem = new OHNContentItem();
    this.updateList();
  }

  removeItem(idx: number) {
    this.itemsList.content.splice(idx, 1);
    this.updateList();
  }

  updateList() {
    this.btnState = 'Saving...';
    console.log("updateState currentState", this.itemsList);
    this.ohnApi.setElementStateSc(this.el.element_slug, {value : JSON.stringify(this.itemsList)}, this.user.smart_contract).subscribe(state => {
      this.itemsList = JSON.parse(state.value);  
      console.log("new state", state);
      this.btnState = 'Save Edits';
      this.ohnApi.setElementStateSc('pain_plan_content_report', {value: [] }, this.user.smart_contract).subscribe(reportState => {
        console.log("reportState", reportState);
      });
    });
  }  

  closeComponent() {
    this.comSub.next({
      action : 'close'
    });
  }

}
