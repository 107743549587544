import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medication-event-report',
  templateUrl: './medication-event-report.component.html',
  styleUrls: ['./medication-event-report.component.scss'],
})
export class MedicationEventReportComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
