<div class="ohn-video-player">
	<video 
		[src]="videoUrl" 
		#OHNVideoPlayer 
		id="OHNVideoPlayer" 
		class="video" 
		webkit-playsinline="webkit-playsinline" 
		playsinline 
		preload="metadata"
		[autoplay]="el.config.autoplay" 
		[controls]="el.config.controls" 
		[loop]="el.config.loop" 
		[muted]="el.config.muted"></video>

<!--	
	<div class="controls" *ngIf="el.config.controls">
		<ion-range aria-label="Video timeline"></ion-range>
		<ion-item lines="none">
			<ion-button (click)="toggleVideo($event)"
				fill="clear" 
				slot="start">
				<ion-icon name="play" slot="icon-only"></ion-icon>
			</ion-button>
			<ion-label>00:00 / 09:00</ion-label>
			<ion-button 
				fill="clear" 
				slot="end">
				<ion-icon name="volume-medium" slot="icon-only"></ion-icon>
			</ion-button>
			<ion-button 
				fill="clear" 
				slot="end">
				<ion-icon name="speedometer" slot="icon-only"></ion-icon>
			</ion-button>
		</ion-item>
	</div>
-->	
</div>