import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-period-to-days-selector',
  templateUrl: './period-to-days-selector.component.html',
  styleUrls: ['./period-to-days-selector.component.scss'],
})
export class PeriodToDaysSelectorComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
