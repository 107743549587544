<div class="ohn-panel">
  <div class="ohn-panel-header">
    <ion-toolbar>
      <ion-title>Select</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="closeInlineComponent()">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-toolbar>
      <ion-searchbar mode="ios" [(ngModel)]="searchFilter"></ion-searchbar>
    </ion-toolbar>
  </div>

  <div class="ohn-panel-content">
    <ng-container *ngIf="!loading.show">
      <ion-item button *ngFor="let item of itemsList.elements | filterBy: ['text']: searchFilter" (click)="processSelected(item)">
        <ion-label class="ion-text-wrap">
          {{item.text}}
        </ion-label>
      </ion-item>
    </ng-container>
    <app-loading-animation *ngIf="loading.show" 
      [aniType]="loading.type">
    </app-loading-animation>
  </div>
</div>