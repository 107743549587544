<div class="ion-padding">
  <h4>Event Type</h4>
  <ion-segment [(ngModel)]="eventType" mode="ios" (ionChange)="clearCurrentEvent($event)">
    <ion-segment-button [value]="'survey'">
      <ion-label>Survey</ion-label>
    </ion-segment-button>
    <ion-segment-button [value]="'workout'">
      <ion-label>Workout</ion-label>
    </ion-segment-button>
    <ion-segment-button [value]="'medication'">
      <ion-label>Medication</ion-label>
    </ion-segment-button>
    <ion-segment-button [value]="'meeting'">
      <ion-label>Meeting</ion-label>
    </ion-segment-button>
  </ion-segment>

  <ion-segment [(ngModel)]="isCustomEvent" mode="ios" class="ohn-toggle-segment">
    <ion-segment-button [value]="false">
      <ion-label>Select from Preset</ion-label>
    </ion-segment-button>
    <ion-segment-button [value]="true">
      <ion-label>Create Custom</ion-label>
    </ion-segment-button>
  </ion-segment>

  <ng-container *ngIf="!isCustomEvent">
    <h4>Select {{eventType}}</h4>
    <ion-item lines="none" class="ion-no-padding" (click)="setOpen(true)">
      <ion-input [(ngModel)]="currentEvent.title" aria-label="Select event" placeholder="Select {{eventType}}" [readonly]="true"></ion-input>
      <ion-button slot="end" fill="clear">
        <ion-icon name="caret-down" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-item>
  </ng-container>
  <ng-container *ngIf="isCustomEvent">
    <h4>{{eventType}} Title</h4>
    <ion-input [(ngModel)]="currentEvent.title" aria-label="Event title" placeholder="Enter {{eventType}} title"></ion-input>
  </ng-container>

  <h4>Start Time</h4>
  <ion-item lines="none" class="ion-no-padding">
    <ion-datetime-button datetime="startTime"></ion-datetime-button>
  </ion-item>

  <ion-modal [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime id="startTime" [(ngModel)]="currentEventTime" presentation="date-time" [preferWheel]="true"></ion-datetime>
    </ng-template>
  </ion-modal>

<!--      <ion-item>
      <ion-select *ngIf="surveyList.elements.length" label="Event" placeholder="Choose from preset">
        <ion-select-option *ngFor="let survey of surveyList.elements; let i = index" value="{{survey.text}}">{{survey.text}}</ion-select-option>
      </ion-select>
      <ion-button slot="end">+</ion-button>
    </ion-item>
    <ion-accordion-group expand="inset" (ionChange)="accordionGroupChange($event)">
      <ion-accordion value="first">
        <ion-item slot="header" color="light">
          <ion-label>Survey Name</ion-label>
          <ion-button slot="end">
            <ion-icon name="add" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-item>
        <div class="ion-padding" slot="content">
          <ion-item button *ngFor="let survey of surveyList.elements; let i = index">{{survey.text}}</ion-item>
        </div>
      </ion-accordion>
    </ion-accordion-group>-->

</div>
  <ion-modal [isOpen]="isModalOpen">
    <ng-template>
      <app-typeahead 
        class="ion-page"
        [elementSlug]="eventType+'s_container'" 
        [inlineCommunicator]="inlineCommunicator"
      ></app-typeahead>
    </ng-template>
  </ion-modal>