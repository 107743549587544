import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { OHNElement } from '../../../models/ohn-instances';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-video-element',
  templateUrl: './video-element.component.html',
  styleUrls: ['./video-element.component.scss'],
})
export class VideoElementComponent  implements OnInit {

  @Input() el: OHNElement;
  @Input() viewMode : string;
  OHNVideoPlayer: HTMLVideoElement;

  validityRules : any[];

  @ViewChild('OHNVideoPlayer')
  set mainVideoEl(el: ElementRef) {
    this.OHNVideoPlayer = el.nativeElement;
  }

  videoUrl: SafeResourceUrl;

  constructor(
    private domSanitizer: DomSanitizer,
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
    this.el.value = 'notViewed';
    this.videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.el.video_url);
    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    this.el.valid = this.validityRules.length > 0 ? false : true; 
      setTimeout(()=>{
        this.OHNVideoPlayer.pause();
        this.OHNVideoPlayer.src= this.el.video_url;
        this.OHNVideoPlayer.load();
        if (this.el.config && this.el.config.displayControls){
          this.OHNVideoPlayer.controls = true;
          this.OHNVideoPlayer.addEventListener('play', (e)=> {
            setTimeout(()=>{
              this.OHNVideoPlayer.currentTime = 1;
              setTimeout(()=>{
                this.OHNVideoPlayer.currentTime = 0;
              }, 10)
            }, 1000)
          },false);
        } else {
          this.OHNVideoPlayer.play();
        }
        
        this.OHNVideoPlayer.addEventListener('ended', (e)=> {
          this.OHNVideoPlayer.pause();
          this.OHNVideoPlayer.currentTime = this.OHNVideoPlayer.duration - 0.5;
          this.el.value = 'viewed'
          this.el.valid = true;  
        },false);
      }, 20);
  }

  toggleVideo(e) {
    console.log(e);
    this.OHNVideoPlayer.paused ? this.OHNVideoPlayer.play() : this.OHNVideoPlayer.pause();
    e.target.children.querySelector('ion-icon').setAttribute('name', 'minus-outline');
  }

  ngOnDestroy() {
    this.OHNVideoPlayer && this.OHNVideoPlayer.pause();
  }

}
