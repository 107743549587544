import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-image-element',
  templateUrl: './image-element.component.html',
  styleUrls: ['./image-element.component.scss'],
})
export class ImageElementComponent  implements OnInit {

  @Input() el: OHNElement;

  constructor() { }

  ngOnInit() {
    this.el.config = this.el.config || {};
    this.el.valid = true;
  }

}
