import { Component, OnInit, Input } from '@angular/core';
import { OhnLogicService } from '../../../services/ohn-logic.service';

@Component({
  selector: 'app-pick-one-radio-element',
  templateUrl: './pick-one-radio-element.component.html',
  styleUrls: ['./pick-one-radio-element.component.scss'],
})
export class PickOneRadioElementComponent  implements OnInit {

  @Input() el: any;

  validityRules : any[];

  constructor(
    private ohnLogicService: OhnLogicService,
  ) { }

  ngOnInit() {
    this.el.value = this.el.value || null;
    this.el.valid = true;
    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    if (this.validityRules.length > 0) {
      this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
    }
  }

  changed() {
    this.el.valid = this.ohnLogicService.processValidityRules(this.validityRules, this.el.controller, this.el.value);
  }

}
