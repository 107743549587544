import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject} from 'rxjs';
import { OhnApiService } from '../../../api/ohn-api.service';
import { OhnLanguageService } from '../../../services/ohn-language.service';
import { OHNElement, OHNUser, OHNLoading } from '../../../models/ohn-instances';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss'],
})
export class TypeaheadComponent  implements OnInit {

  @Input() elementSlug: string;
  @Input() inlineCommunicator : Subject<any>;

  itemsList: OHNElement;
  loading: OHNLoading = new OHNLoading();
  searchFilter: string = "";

  constructor(    
    private ohnApi: OhnApiService,
    private lS: OhnLanguageService
  ) { }

  ngOnInit() {
    this.loading.type = 'skeleton';
    this.loading.text = '';
    this.loading.show = true;
    this.loadItemsList();
  }

  loadItemsList(){
    this.ohnApi.getElement(this.elementSlug, 3).subscribe(itemsList => {
      this.itemsList = itemsList;
      console.log(this.itemsList);
      this.loading.show = false;
    });
  }

  processSelected(item: OHNElement) {
    console.log("processSelected", item);
    this.inlineCommunicator.next({
      action : 'process',
      el: item
    });
  }

  closeInlineComponent() {
    this.inlineCommunicator.next({
      action : 'close'
    });
  }

}
