import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router, NavigationExtras } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { OhnLanguageService } from './services/ohn-language.service';

import { AuthService } from './services/auth.service';
 
import { WEB_APP_TITLE, DEBUG_MODE } from '../environments/environment';
 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {

  constructor(
    private platform: Platform,
    private router: Router,
    private titleService: Title,
    private authenticationService: AuthService,
    public lS: OhnLanguageService
  ) {
    this.lS.init();
    this.initializeApp();
  }
 
  initializeApp() {
    this.authenticationService.authState.subscribe((state: any) => {
      if (!state) {
        this.router.navigate(['login'], { replaceUrl: true });
      } else {
        this.router.navigate(['secured']);
      }
    });
    this.platform.ready().then(() => {
      this.titleService.setTitle(WEB_APP_TITLE);
    })
  }
}
