import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dataframe-event-view',
  templateUrl: './dataframe-event-view.component.html',
  styleUrls: ['./dataframe-event-view.component.scss'],
})
export class DataframeEventViewComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
