import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hidden-counter',
  templateUrl: './hidden-counter.component.html',
  styleUrls: ['./hidden-counter.component.scss'],
})
export class HiddenCounterComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
