import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-video-embed-element',
  templateUrl: './video-embed-element.component.html',
  styleUrls: ['./video-embed-element.component.scss'],
})
export class VideoEmbedElementComponent  implements OnInit {

  @Input() el: OHNElement;
  @Input() viewMode : string;

  videoUrl: SafeResourceUrl;
  validityRules : any[];

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.el.video_url = this.parseYoutubeURL(this.el.video_url);
    this.videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.el.video_url);   
    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    this.el.valid = this.validityRules.length > 0 ? false : true; 
  }

  parseYoutubeURL(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return "https://www.youtube.com/embed/" + match[2] + '?rel=0';
    } else {
      return "";
    }
  }

}
