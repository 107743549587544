import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notes-element',
  templateUrl: './notes-element.component.html',
  styleUrls: ['./notes-element.component.scss'],
})
export class NotesElementComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}
  
}
