import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser, OHNCalendarEvent, OHNMedication, OHNMeeting } from '../../models/ohn-instances';
import { Subject } from 'rxjs';

import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-agenda-event-view',
  templateUrl: './agenda-event-view.component.html',
  styleUrls: ['./agenda-event-view.component.scss'],
})
export class AgendaEventViewComponent  implements OnInit {

  @Input() event : OHNCalendarEvent|OHNMedication|OHNMeeting;
  @Input() calendarSlug : string;
  @Input() user : OHNUser;
  @Input() inlineCommunicator : Subject<any>;

  reportState : any;

  constructor() { }

  ngOnInit() {

  }

}
