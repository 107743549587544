<ng-container *ngIf="!loading.show && diary">

  <ng-container *ngIf="mini"> 
    <div (click)="showDiaryModal = true;" class="mini-view">      
      <ion-row>
        <ion-col>
          <img alt="Enter Health Data" src="assets/art/health-data.svg">
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button fill="clear" size="small">
            <ion-icon name="add" slot="start"></ion-icon>
            Add Health Data
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </ng-container>

  <ng-container *ngIf="!mini"> 
    <ion-content mode="md">
      <ion-list mode="ios">
        <ion-item *ngFor="let field of diary.elements" button (click)="openFieldEditingModal(field)">
          <ion-label><h2>{{field.text}}</h2></ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-container>

  <ion-modal [isOpen]="showDiaryModal" mode="md">
    <ng-template>
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>My Health Data</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="showDiaryModal = false;">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content mode="ios">
        <ion-card *ngFor="let field of diary.elements" (click)="openFieldEditingModal(field)">
          <ion-card-content>
            <ion-item lines="none">
              <ion-icon *ngIf="field.controller == 'ohnWeightController'" name="scale" slot="start" color="primary"></ion-icon>
              <ion-icon *ngIf="field.controller == 'ohnBloodPressureController'" name="heart-half" slot="start" color="primary"></ion-icon>
              <ion-label><strong>{{field.text}}</strong></ion-label>
              <ion-icon slot="end" name="add" color="primary"></ion-icon>
            </ion-item>
          </ion-card-content>
        </ion-card>
      </ion-content>
    </ng-template>
  </ion-modal>

  <ion-modal [isOpen]="dataEditingOpened" mode="md">
    <ng-template>
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>{{selectedField.text}}</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="closeDataInputModal()">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ng-container *ngIf="selectedField.controller == 'ohnWeightController'" >
          <ion-item>
            <ion-input [(ngModel)]="fieldValue" label="{{selectedField.text}}" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
          </ion-item>
        </ng-container>
        <ng-container *ngIf="selectedField.controller == 'ohnBloodPressureController'" >
          <ion-item>
            <ion-input [(ngModel)]="fieldValue.ohn_bp_systolic" label="Systolic:" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
          </ion-item>
          <ion-item>
            <ion-input [(ngModel)]="fieldValue.ohn_bp_diastolic" label="Diastolic:" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
          </ion-item>
        </ng-container>
      </ion-content>

      <ion-footer>
        <ion-toolbar color="primary">
          <ion-button (click)="saveData()" mode="ios" expand="block" fill="clear" color="light" [disabled]="saving">
            <ion-icon *ngIf="!saving" name="checkmark" slot="start"></ion-icon>
            <ion-spinner *ngIf="saving" name="crescent" slot="start" color="light"></ion-spinner>
            {{saving ? 'Saving...' : 'Save'}}
          </ion-button>
        </ion-toolbar>
      </ion-footer>

    </ng-template>
  </ion-modal>
</ng-container>

<app-loading-animation *ngIf="loading.show" [aniText]="loading.text" [aniType]="loading.type"></app-loading-animation>
