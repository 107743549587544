import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weight-input-element',
  templateUrl: './weight-input-element.component.html',
  styleUrls: ['./weight-input-element.component.scss'],
})
export class WeightInputElementComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
