import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser, OHNLoading } from '../../models/ohn-instances';
import { OhnApiService } from '../../api/ohn-api.service';
import { API_URL, DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-html-template-renderer',
  templateUrl: './html-template-renderer.component.html',
  styleUrls: ['./html-template-renderer.component.scss'],
})
export class HtmlTemplateRendererComponent  implements OnInit {

  @Input() report: any;
  @Input() user: OHNUser;

  loading: OHNLoading = new OHNLoading();
  reportContent: any[] = [];
  fileURL: string;

  constructor(
    private ohnApi: OhnApiService
  ) {}

  ngOnInit() {
    DEBUG_MODE && console.log("report", this.report);
    this.loading.type = 'triad';
    this.loading.show = true;
    this.mapReportContent();
  }

  mapReportContent() {
    for (let [k,v] of Object.entries(this.report.value.text)) {
      let obj: any = {text: this.report.value.text[k], element_slug: this.report.value.element_slug[k], val: JSON.parse(this.report.value.value[k])};
      this.reportContent.push(obj);
    }

    DEBUG_MODE && console.log("reportContent", this.reportContent);
    this.loading.show = false;
    //setTimeout(()=>{this.getPDF()}, 200);
  }  

  ngAfterViewInit() {
    this.getPDF();
  }

  getPDF() { 
    this.ohnApi.setElementStateSc('pain_plan_content_report_printout', {value : document.getElementById('htmlContainer').innerHTML}, this.user.smart_contract).subscribe((pdfLinks: any) => {
      this.fileURL = API_URL + '/reports/' + pdfLinks.value;
      DEBUG_MODE && console.log('getPDF fileURL',this.fileURL);
    });
  }

}
