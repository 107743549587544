import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { OHNUser } from '../models/ohn-instances';
 
const TOKEN_KEY = 'ohn-auth-token';
const ME_USER = 'ohnMe';
 
@Injectable({
  providedIn: 'root'
})
export class AuthService {
 
  authState: any = new BehaviorSubject(false);
  logoutEvent : any = new Subject();
  me: OHNUser = <OHNUser>{};
 
  constructor(private platform: Platform) { 
    this.platform.ready().then(() => {
      this.checkToken();
    });
  }
 
  checkToken() {
    if (localStorage.getItem(TOKEN_KEY)) {
      this.authState.next(true);
    }
  }
 
  login(token:string, account: OHNUser) {
    localStorage.setItem(TOKEN_KEY, 'Bearer ' + token);
    localStorage.setItem(ME_USER, JSON.stringify(account));
    this.authState.next(true);
  }
 
  logout() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ME_USER);
    localStorage.removeItem('OHN_DEBUG_SERV_URL');
    this.logoutEvent.next(true);
    this.authState.next(false);
    location.reload();
  }
 
  isAuthenticated() {
    return this.authState.value;
  }
 
}