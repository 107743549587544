import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { OhnApiService } from '../../api/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { OHNElement, OHNCalendarEvent, OHNUser, OHNCalendarItem, OHNEventPreset, OHNLoading} from '../../models/ohn-instances';
import { format } from "date-fns";

@Component({
  selector: 'app-ohn-calendar',
  templateUrl: './ohn-calendar.component.html',
  styleUrls: ['./ohn-calendar.component.scss'],
})
export class OhnCalendarComponent  implements OnInit {

  @Input() user: OHNUser;
  @Input() mini: boolean;
 
  date = new Date();
  months: string[] = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
  ];
  curDate: Date = new Date();
  curDay: any = {};
  year: number;
  month: number;
  loading: OHNLoading = new OHNLoading(true, 'triad');
  eventSource: any = {};
  historyPeriod : any = {};
  selectedDayEvents : OHNCalendarItem[] = [];

  showEventModal: boolean = false;
  time: string;
  showCalendarModal: boolean = false;

  constructor(
    private ohnApi: OhnApiService,
  ) { 
    this.year = this.date.getFullYear();
    this.month = this.date.getMonth();
    this.historyPeriod.startDate = new Date(new Date().getTime() - 34 * 24 * 3600000);
    this.historyPeriod.endDate = new Date(new Date().getTime() + 28 * 24 * 3600000);
  }
  calendarView: any[] = [];

  newEvent: OHNEventPreset = new OHNEventPreset();

  ngOnInit() {
    this.time = this.date.toISOString();
    this.loadCalendarState(new Date());
    //this.initCalendar();
    console.log("User", this.user);
  }

  loadCalendarState(targetDate: Date) {
    this.ohnApi.getElementHistoryByDatesGroupedSc('calendar_container', OhnService.getStringDatesPeriodForCalendar(targetDate, 'month', 1), this.user.smart_contract).subscribe(history => {
      console.log("history",history);
      let tmpObject = {};
      Object.keys(history).forEach(k => {
        tmpObject[k] = history[k].map(e => {
          return new OHNCalendarItem(e)
        })
      })
     
      this.eventSource = Object.assign(this.eventSource, tmpObject)
      
      console.log(this.eventSource)
      
      this.loading.show = false;
      this.initCalendar();
      //this.getSelectedDateEvents(this.calendar.selectedDate);
      //this.eventCalendar.loadEvents();
    });
  }

  initCalendar() {
    let dayone = new Date(this.year, this.month, 1).getDay();
 
    // Get the last date of the month
    let lastdate = new Date(this.year, this.month + 1, 0).getDate();
 
    // Get the day of the last date of the month
    let dayend = new Date(this.year, this.month, lastdate).getDay();
 
    // Get the last date of the previous month
    let monthlastdate = new Date(this.year, this.month, 0).getDate();
 
    // Variable to store the generated calendar HTML
    this.calendarView = [];
 
    // Loop to add the last dates of the previous month
    for (let i = dayone; i > 0; i--) {
      this.calendarView.push({cls: 'inactive', val: null, date: null});
    }

    for (let i = 1; i <= lastdate; i++) {
 
      // Check if the current date is today
      let isToday = i === this.date.getDate()
          && this.month === new Date().getMonth()
          && this.year === new Date().getFullYear()
          ? "active"
          : "";
      const stringDate = format(new Date(this.year, this.month, i), "yyyy-MM-dd");
      const calendarDay = {cls: isToday, val: i, date: new Date(this.year, this.month, i), stringDate};
      this.calendarView.push(calendarDay);
      if (isToday == "active") this.selectDate(calendarDay)
    }
 
    // Loop to add the first dates of the next month
    for (let i = dayend; i < 6; i++) {
      this.calendarView.push({cls: 'inactive', val: null, date: null});
    }

    this.calendarView.forEach(d => {
      if (this.eventSource[d.stringDate]) {
        d.cls = 'has-events'
      }
    })

    console.log("calendarView", this.calendarView);
  }

  changeMonth(dir: number) {
    this.selectedDayEvents = [];

    this.month = this.month + dir;

    if (this.month < 0 || this.month > 11) {
 
      // Set the date to the first day of the 
      // month with the new year
      this.date = new Date(this.year, this.month, new Date().getDate());
 
      // Set the year to the new year
      this.year = this.date.getFullYear();

      // Set the month to the new month
      this.month = this.date.getMonth();
    
    } else {
      // Set the date to the current date
      this.date = new Date();
    }

    const monthfirstdate = new Date(this.year, this.month, 0);
    console.log(monthfirstdate)
    if (dir < 0 && monthfirstdate.getTime() <= this.historyPeriod.startDate.getTime()) {
      this.historyPeriod.startDate = new Date(monthfirstdate.getTime() - (58 * 24 * 3600000));
      this.loadCalendarState(monthfirstdate);
    }

    const monthlastdate = new Date(this.year, this.month + 1, 0);
    //console.log(monthlastdate)
    if (dir > 0 && monthlastdate.getTime() >= this.historyPeriod.endDate.getTime()) {
      this.historyPeriod.endDate = new Date(monthlastdate.getTime() + (58 * 24 * 3600000));
      this.loadCalendarState(monthlastdate);
    }

    this.initCalendar();

  }

  getLocalISOString(date) {
    const offset = date.getTimezoneOffset()
    const offsetAbs = Math.abs(offset)
    const isoString = new Date(date.getTime() - offset * 60 * 1000).toISOString()
    return `${isoString.slice(0, -1)}${offset > 0 ? '-' : '+'}${String(Math.floor(offsetAbs / 60)).padStart(2, '0')}:${String(offsetAbs % 60).padStart(2, '0')}`
  }

  selectDate(day: any) {
    console.log("selectDate day", day);
    if (this.eventSource[day.stringDate]) {
      this.selectedDayEvents = this.eventSource[day.stringDate]
    } else {
      this.selectedDayEvents = [];
    }
    console.log("this.selectedDayEvents", this.selectedDayEvents);
    this.curDate = day.date;
    this.curDay = day;
    this.time = new Date().toISOString();
    console.log("Select date", new Date());
    console.log("Select time", this.time);
    console.log("Select time TZ", this.getLocalISOString(new Date()));

    this.newEvent = new OHNEventPreset();
    this.newEvent.controller = 'meeting';
    this.newEvent.startTime = day.date;
  }

  openEventModal() {
    this.newEvent = new OHNEventPreset();
    this.newEvent.controller = 'meeting';
    this.showEventModal = true;
        let linkDateStart = new Date(this.newEvent.startTime).toISOString().split("-").join("").split(":").join("").split('.')[0];
    let linkDateEnd = new Date(+this.newEvent.startTime + 360000).toISOString().split("-").join("").split(":").join("").split('.')[0];
    console.log("Start/End", linkDateStart, linkDateEnd);
  }

  closeEventModal() {
    this.showEventModal = false;
  }

  addEvent() {
    let t = new Date(this.time);
    console.log("t", t);
    this.curDate.setHours(t.getHours(), t.getMinutes());
    console.log("this.curDate", this.curDate);
    this.newEvent.startTime = new Date(this.curDate);
    let linkDateStart = new Date(this.newEvent.startTime).toISOString().split("-").join("").split(":").join("").split('.')[0]+'Z';
    let linkDateEnd = new Date(+this.newEvent.startTime + 360000).toISOString().split("-").join("").split(":").join("").split('.')[0]+'Z';
    console.log("Start/End", linkDateStart, linkDateEnd);
    this.newEvent.supplementary_action.datetime = new Date(+new Date() + 60000);
    this.newEvent.supplementary_action.parameters.to = this.user.email;
    this.newEvent.supplementary_action.parameters.subject = `Invitation: ${this.newEvent.title}`;
    this.newEvent.supplementary_action.parameters.text =  `<h3>When: ${this.newEvent.startTime}</h3><p><strong>Agenda:</strong> ${this.newEvent.description}</p>`;
    if (this.newEvent.contentObject.url) {
      this.newEvent.supplementary_action.parameters.text += `<p><a href="${this.newEvent.contentObject.url}" target="_blank">${this.newEvent.contentObject.url}</a></p>`;
    }
    this.newEvent.supplementary_action.parameters.text += `<a style="display:inline-block;background:#6f6eb0;color:#fff;text-decoration:none;font-weight:bold;border-radius:8px;padding:8px 16px;" href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=${this.newEvent.title}&details=${this.newEvent.description || ''}+${this.newEvent.contentObject.url || ''}&dates=${linkDateStart}/${linkDateEnd}" target="_blank">Add To Google Calendar</a>`;


    console.log("Add event", this.newEvent);

    this.scheduleEvent();
  }

  scheduleEvent() {
    this.ohnApi.setElementStateSc('calendar_container', {value: this.newEvent}, this.user.smart_contract).subscribe(ev => {
      console.log("Event", ev);
      let stringDate = new Date(ev.startTime).toISOString().split("T")[0];
      (this.eventSource[stringDate] ??= []).push(new OHNCalendarItem(ev));
      console.log("this.eventSource", this.eventSource);
      this.selectDate(this.curDay);
      this.initCalendar();
      this.closeEventModal();
    });
  }

  deleteEvent(event: OHNCalendarItem) {
    console.log("Dlete Event:", event);
    
    this.ohnApi.deleteElementStateSc('calendar_container', {value: {id : event.id}}, this.user.smart_contract).subscribe(events => {
      this.selectedDayEvents = events;
    });
    
  }

}
