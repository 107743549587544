<ng-container *ngFor="let element of elements" [ngSwitch]="element.controller">

  <app-content-raw-element *ngSwitchCase="'contentElementController'" [el]="element" [user]="user"></app-content-raw-element>

  <app-video-element *ngSwitchCase="'videoElementController'" [el]="element" ></app-video-element>

  <app-video-embed-element *ngSwitchCase="'videoEmbedElementController'" [el]="element" ></app-video-embed-element>

  <app-audio-element *ngSwitchCase="'audioElementController'" [el]="element" ></app-audio-element>

  <!--<app-audio-embed-element *ngSwitchCase="'audioEmbedElementController'" [el]="element" ></app-audio-embed-element>-->

  <app-pick-one-radio-element *ngSwitchCase="'pickOneDefaultController'" [el]="element"></app-pick-one-radio-element>

  <app-string-field-element *ngSwitchCase="'stringFieldController'" [el]="element"></app-string-field-element>

  <app-image-element *ngSwitchCase="'imageElementController'" [el]="element"></app-image-element>

  <app-numeric-slider-element *ngSwitchCase="'numericSliderFieldController'" [el]="element" ></app-numeric-slider-element>

  <app-pick-many-element *ngSwitchCase="'pickManyDefaultController'" [el]="element"></app-pick-many-element>

  <app-external-link-element *ngSwitchCase="'linkElementController'" [el]="element" [user]="user"></app-external-link-element>

  <app-embed-element *ngSwitchCase="'embedElementController'" [el]="element"></app-embed-element>
<!--
  <app-date-field-element *ngSwitchCase="'dateTimeFieldController'" [el]="element"></app-date-field-element>

  <app-fitbit-heart-rate *ngSwitchCase="'fitbitHeartRateElementController'" [el]="element"></app-fitbit-heart-rate>

  <app-heart-rate-zones-element *ngSwitchCase="'heartRateZonesElementController'" [el]="element"></app-heart-rate-zones-element>

  <app-height-input-element *ngSwitchCase="'heightInputElementController'" [el]="element" ></app-height-input-element>

  <app-hidden-counter *ngSwitchCase="'hiddenCounterController'" [el]="element"></app-hidden-counter>

  <app-image-element *ngSwitchCase="'imageElementController'" [el]="element"></app-image-element>

  <app-list-field-element *ngSwitchCase="'listElementController'" [el]="element"></app-list-field-element>

  <app-noise-level-element *ngSwitchCase="'noiseLevelElementController'" [el]="element"  [user]="user"></app-noise-level-element>

  <app-notes-element *ngSwitchCase="'notesController'" [el]="element" [user]="user"></app-notes-element>

  <app-numeric-field-element *ngSwitchCase="'numericFieldController'" [el]="element"></app-numeric-field-element>

  <app-numeric-pick-one *ngSwitchCase="'numericPickOneController'" [el]="element"></app-numeric-pick-one>

  <app-numeric-slider-element *ngSwitchCase="'numericSliderFieldController'" [el]="element" ></app-numeric-slider-element>

  <app-period-to-days-selector *ngSwitchCase="'periodToDaysController'" [el]="element" [allElements]="allElements"></app-period-to-days-selector>

  <app-pick-many-element *ngSwitchCase="'pickManyDefaultController'" [el]="element"></app-pick-many-element>

  <app-pick-one-radio-element *ngSwitchCase="'pickOneDefaultController'" [el]="element"></app-pick-one-radio-element>

  <app-printout-report *ngSwitchCase="'printoutReportController'" [el]="element" [allElements]="allElements" [user]="user"></app-printout-report>

  <app-randomization-element *ngSwitchCase="'randomizationElementController'" [el]="element" [user]="user"></app-randomization-element>

  <app-session-counter *ngSwitchCase="'sessionCounter'" [el]="element" [user]="user"></app-session-counter>

  <app-string-field-element *ngSwitchCase="'stringFieldController'" [el]="element"></app-string-field-element>

  <app-timer-element *ngSwitchCase="'timerElementController'" [el]="element"></app-timer-element>

  <app-video-element *ngSwitchCase="'videoElementController'" [el]="element" ></app-video-element>

  <app-weight-input-element *ngSwitchCase="'weightFieldController'" [el]="element"></app-weight-input-element>

  <app-yes-no-input-element *ngSwitchCase="'yesNoController'" [el]="element"></app-yes-no-input-element> -->

  <div *ngSwitchDefault>
    {{element.text}}
  </div>

</ng-container>