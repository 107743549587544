import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../../api/ohn-api.service';
import { OHNElement, OHNUser, OHNBookmark } from '../../../models/ohn-instances';
import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-external-link-element',
  templateUrl: './external-link-element.component.html',
  styleUrls: ['./external-link-element.component.scss'],
})
export class ExternalLinkElementComponent  implements OnInit {

  @Input() el: OHNElement;
  @Input() user : OHNUser;
  saving: boolean = false;
  bookmarked: boolean = false;
  stateId: string;
  elStateId : string;
  displayingNotesFor : string[] = [];
  hasValidityRule : boolean;

  constructor(
    private ohnApi: OhnApiService,
    public lS: OhnLanguageService
  ) { }

  ngOnInit() {
    if(!this.el.config.isButton) {
      this.getState();
    }
    this.el.valid = true;
    if(this.el.config.validityRules) {
      this.el.valid = false;
      this.hasValidityRule = true;
    }
  }

  getState() {
    this.ohnApi.getElementStateSc(this.el.element_slug, this.user.smart_contract).subscribe(state =>{
      this.bookmarked = state.value && state.value.value != "";
      if (this.bookmarked) {
        this.stateId = state.value.value;
        this.elStateId = state.value.id;
      }
    });
  }

  linkClicked() {
    if (this.hasValidityRule) {
      this.el.valid = true;
    }
  }

}
