<ng-container *ngIf="!loading.show">
  <div class="ohn-panel">
    <div class="ohn-panel-header">
      <ion-toolbar *ngIf="!inlineView">
        <ion-buttons slot="start" *ngIf="!lockCloseButton">
          <ion-button (click)="closeModal()">
            <ion-icon name="arrow-back" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{parentElement.text}}</ion-title>
        <ion-buttons slot="end" *ngIf="!lockCloseButton">
          <ion-button (click)="closeModal()">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar *ngIf="inlineView && !lockCloseButton">
        <ion-title>{{parentElement.text}}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="closeInlineComponent()">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </div>

    <div class="ohn-panel-content ion-padding">
      <app-basic-elements-renderer 
        [elements]="currentPage.elements" 
        [user]="user" 
        [wideScreenAvailable]="parentElement.config.wideScreenWithVideo" 
        [allElements]="basicElements" 
        (click)="checkClickAction()">
      </app-basic-elements-renderer>
    </div>
    
    <div class="ohn-panel-footer" *ngIf="!(isSinglePage && viewOnly)">
      <ion-row class="ion-justify-center ion-align-center">
        <ion-col size="4.5">
          <ion-button *ngIf="(currentPage.element_slug != parentElement.elements[0].element_slug) && !isSinglePage" (click)="goPrevious()" mode="ios" expand="block" shape="round">
            <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
            {{lS.g('previous')}}
          </ion-button>
        </ion-col>
        <ion-col size="3" class="ion-text-center">
          <ng-container *ngIf="contentLikesEnabled">
            <ng-container *ngIf="!currentPage.liked">
              <ion-button (click)="toggleCategoriesAlert(true)" mode="ios" fill="clear" expand="block">
                <ion-icon name="heart-outline" color="secondary"></ion-icon>
              </ion-button>
            </ng-container>
            <ng-container *ngIf="currentPage.liked">
              <ion-button (click)="removeCurrentPageFromFavorites()" mode="ios" fill="clear" expand="block">
                <ion-icon name="heart" color="secondary"></ion-icon>
              </ion-button>
            </ng-container>
          </ng-container>
        </ion-col>
        <ion-col size="4.5">
          <ion-button *ngIf="!isFinalNode()" (click)="goNext()" mode="ios" expand="block" [disabled]="pageNotValidated()" shape="round">
            <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>
            {{lS.g('next')}}
          </ion-button>
          <ion-button *ngIf="isFinalNode() && !hideDoneButton" (click)="finish()" mode="ios" expand="block" [disabled]="pageNotValidated()" shape="round">
            {{lS.g('done')}}
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>

</ng-container>

<app-loading-animation *ngIf="loading.show" 
  [aniType]="loading.type" 
  [aniText]="loading.text"
></app-loading-animation>

<ng-container *ngIf="contentLikesEnabled">
  <ion-alert 
    class="ohn-alert"
    [isOpen]="displayCategoriesSelection"
    header="{{lS.g('selectFavCat')}}"
    [buttons]="likesAlertButtons"
    [inputs]="likesAlertInputs"
    (didDismiss)="toggleCategoriesAlert(false, $event)"
  ></ion-alert>
</ng-container>