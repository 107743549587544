import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-session-counter',
  templateUrl: './session-counter.component.html',
  styleUrls: ['./session-counter.component.scss'],
})
export class SessionCounterComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
