import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../api/ohn-api.service';
import { OHNUser, OHNLoading } from '../../models/ohn-instances';

@Component({
  selector: 'app-devices-sync-info',
  templateUrl: './devices-sync-info.component.html',
  styleUrls: ['./devices-sync-info.component.scss'],
})
export class DevicesSyncInfoComponent  implements OnInit {

  @Input() user: OHNUser;
  @Input() mini: boolean;

  devicesInfo : any;

  circleState : string;

  deviceList : string[] = ["FitBit", "Actigraph", "IQAir", "SleepImage", "iHealth"];

  showDeviceModal: boolean = false;

  loading: OHNLoading = new OHNLoading(true, 'triad');

  constructor(
    private ohnApi: OhnApiService,
  ) { }

  ngOnInit() {
    this.ohnApi.getDevicesSyncInfo(this.user.username).subscribe(info => {
      this.devicesInfo = info;
      this.getCircleState();
    })
  }

  getCircleState() {
    this.circleState = 'success';
    for (const d of this.deviceList) {
      if (this.devicesInfo[d].indexOf('The last successful sync') >= 0) {
        this.circleState = 'warning';
        break;
      }
      if (this.devicesInfo[d].indexOf('The synchronization with this device') >= 0) {
        this.circleState = 'danger';
        break;
      }
    }
    this.loading.show = false;
  }

}
