    <ng-container [ngSwitch]="event.controller">
      <app-dataframe-event-view *ngSwitchCase="'dataframe'"></app-dataframe-event-view>
      <app-medication-event-view *ngSwitchCase="'medication'" 
        [calendarSlug]="calendarSlug" 
        [event]="event" 
        [inlineCommunicator]="inlineCommunicator"
        [user]="user"></app-medication-event-view>

      <app-meeting-event-view *ngSwitchCase="'meeting'" 
        [calendarSlug]="calendarSlug" 
        [event]="event" 
        [inlineCommunicator]="inlineCommunicator"
        [user]="user"></app-meeting-event-view>

      <app-survey-event-view *ngSwitchCase="'survey'" 
        [event]="event"
        [inlineCommunicator]="inlineCommunicator" 
        [user]="user"></app-survey-event-view>

      <app-workout-event-view *ngSwitchCase="'workout'" 
        [event]="event" 
        [inlineCommunicator]="inlineCommunicator"
        [user]="user"></app-workout-event-view>
<!--      
      <ng-container *ngSwitchCase="'survey'">
        <ion-row class="ion-align-items-center ohn-size-h-100 ion-text-center eve">
          <ion-col (click)="startSurvey()" class="event-view">
            <img src="assets/art/event-view-survey.svg">
            <h3>Ready? Tap &laquo;Start&raquo;!</h3>
          </ion-col>
        </ion-row>
      </ng-container>
      <ng-container *ngSwitchCase="'workout'">
        <ion-row *ngIf="!event.contentObject.isFitbitWorkout" class="ion-align-items-center ohn-size-h-100 ion-text-center">
          <ion-col (click)="startSurvey()" class="event-view">
            <ion-icon name="play-circle" color="secondary"></ion-icon>
            <ion-text color="secondary"><h3>Start Workout</h3></ion-text>
          </ion-col>
        </ion-row>
      </ng-container>
      <ng-container *ngSwitchCase="'dataframe'">
        <ion-row>
          {{reportState}}
        </ion-row>
      </ng-container>
      <ng-container *ngSwitchCase="'medication'">
        <ion-row>
          <ion-col>
            <h3>Medication name :</h3>
          </ion-col>
          <ion-col>
            <h3>{{event.title}}</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h3>Description : </h3>
          </ion-col>
          <ion-col>
            <h3>{{event.description}}</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h3>Dose : </h3>
          </ion-col>
          <ion-col>
            <h3>{{event.contentObject.dose}}</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h3>Intake Time : </h3>
          </ion-col>
          <ion-col>
            <h3>{{event.startTime | date:'EEEE, MMMM d, y, h:mm:ss a'}}</h3>
          </ion-col>
        </ion-row>
      </ng-container>
      <ng-container *ngSwitchCase="'meeting'">
        <ion-row>
          <ion-col>
            <h3>Meeting name :</h3>
          </ion-col>
          <ion-col>
            <h3>{{event.title}}</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h3>Description : </h3>
          </ion-col>
          <ion-col>
            <h3>{{event.description}}</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h3>Meeting URL : </h3>
          </ion-col>
          <ion-col>
            <h3>{{event.contentObject.url}}</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h3>Meeting Time : </h3>
          </ion-col>
          <ion-col>
            <h3>{{event.startTime | date:'EEEE, MMMM d, y, h:mm:ss a'}}</h3>
          </ion-col>
        </ion-row>
      </ng-container>
-->
    </ng-container>