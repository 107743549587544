import { Component, OnInit, Input } from '@angular/core';
import { Subject, Subscription} from 'rxjs';
import { OhnApiService } from '../../api/ohn-api.service';
import { OhnLanguageService } from '../../services/ohn-language.service';
import { OHNElement, OHNUser, OHNCalendarEvent } from '../../models/ohn-instances';

@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss'],
})
export class CalendarEventComponent  implements OnInit {

  eventType: string = "survey";
  isCustomEvent: boolean = false;
  currentEvent: OHNCalendarEvent = new OHNCalendarEvent();
  currentEventTime: any;
  isModalOpen: boolean = false;
  inlineCommunicator : Subject<any> = new Subject();
  pageSubs = new Subscription();

  constructor(
    private ohnApi: OhnApiService,
    private lS: OhnLanguageService
  ) { }

  ngOnInit() {
    console.log("currentEvent", this.currentEvent);
    let nowDate = new Date();
    this.currentEventTime = nowDate.toISOString();
    console.log("currentEventTime", this.currentEventTime);
    this.inlineCommunicator.subscribe(request=>{
      switch (request.action) {
        case 'close' : 
          this.setOpen(false);
          break;
        case 'process' : 
          this.currentEvent.title = request.el.text;
          this.setOpen(false);
          break;
      }
    });
    this.pageSubs.add(this.inlineCommunicator);
  }
/*
  scheduleEvent(event: any) {
    this.ohnApi.setElementStateSc(this.el.element_slug, {value: event}, this.user.smart_contract).subscribe(event => {
      this.loadCalendarState(this.calendar.selectedDate);
    });
  }
*/

  clearCurrentEvent($event: any) {
    this.currentEvent = new OHNCalendarEvent();
  }

  setOpen(isOpen: boolean) {
    this.isModalOpen = isOpen;
  }

  ngOnDestroy() {
    (this.pageSubs) && this.pageSubs.unsubscribe();
  }

}
