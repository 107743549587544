<ng-container [ngSwitch]="aniType">
  <ion-item *ngSwitchCase="'skeleton'">
    <ion-label>
      <h3>
        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
      </h3>
      <p>
        <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
      </p>
    </ion-label> 
  </ion-item>

  <div *ngSwitchCase="'triad'" class="loading-animation-triad">
    <div class="loading-animation-triad-holder">
      <div class="loading-animation-triad-inner one"></div>
      <div class="loading-animation-triad-inner two"></div>
      <div class="loading-animation-triad-inner three"></div>
    </div>
    <div class="loading-animation-triad-text">{{aniText}}</div>
  </div>

  <div *ngSwitchDefault class="loading-animation-default">
    <div class="loading-animation-default-holder">
      <div class="loading-animation-default-spinner"></div>
      <span class="loading-animation-default-text">{{lS.g('loading')}}</span>
    </div>
  </div>
</ng-container>