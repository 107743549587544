import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http'; 

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './app.shared.module';

import { NgxEchartsModule } from 'ngx-echarts';
import { NgPipesModule } from 'ngx-pipes';
/*
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Health } from '@awesome-cordova-plugins/health/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
*/

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({mode: 'md'}), 
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgPipesModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [
    { 
      provide: RouteReuseStrategy, 
      useClass: IonicRouteStrategy 
    }/*,
    
    InAppBrowser,
    Insomnia,
    Health,
    Device
    */
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
