import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../api/ohn-api.service';
import { OHNElement, OHNLoading, OHNUser} from '../../models/ohn-instances';
import { Subject} from 'rxjs';
import { OhnLanguageService } from '../../services/ohn-language.service';

import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-ohn-form',
  templateUrl: './ohn-form.component.html',
  styleUrls: ['./ohn-form.component.scss'],
})
export class OhnFormComponent  implements OnInit {

  @Input() parentElement: OHNElement;
  @Input() formElement: OHNElement;
  @Input() user: OHNUser;
  @Input() stateId: string;
  @Input() actionSub : Subject<any>;

  loading: OHNLoading = new OHNLoading(false, 'skeleton');

  constructor(
    private ohnApi: OhnApiService,
    public lS: OhnLanguageService
  ) { }

  ngOnInit() {
    //this.loadPage();
    DEBUG_MODE && console.log("formElement", this.formElement);
  }
/*
  loadPage() {
    this.ohnApi.getElement(this.pageElement.element_slug, 2).subscribe(element => {
      this.pageElement = element;
      DEBUG_MODE && console.log("pageElement", this.pageElement);
      this.loading.show = false;
    });
  }
*/
  savePageState() {
    let stateObject: any = {value : {}};

    this.formElement.elements.forEach(e => {
      //stateObject.value[e.element_slug] = (e.value !== undefined && e.value !== null) ? e.value : '';
      if (e.value !== undefined && e.value !== null) {
        stateObject.value[e.element_slug] = e.value;
      } 
    });

    DEBUG_MODE && console.log("savePageState stateObject", stateObject);
/*
    if (this.stateId) {
      
      this.ohnApi.patchElementStateSc(this.formElement.element_slug, {value : {id : this.stateId, value: stateObject }}, this.user.smart_contract).subscribe(state => {
        this.closeForm(stateObject);
      });
    } else { */
      this.ohnApi.setElementStateSc(this.formElement.element_slug, stateObject, this.user.smart_contract).subscribe(state => {
        DEBUG_MODE && console.log("savePageState state", state);
        this.savePageReportState(stateObject);
      });
   // }

  }

  savePageReportState(data? : any) {
    DEBUG_MODE && console.log("savePageReportState data", data);
    DEBUG_MODE && console.log("savePageReportState data", {...data.value});
    
    if (this.parentElement.report_element) {
      let slugs: string[] = Object.keys({...data.value}); 
    DEBUG_MODE && console.log("savePageReportState slugs", slugs);
      if (this.stateId) {
        this.ohnApi.patchElementStateSc(this.parentElement.report_element.element_slug, {value : {id : this.stateId, value: slugs }}, this.user.smart_contract).subscribe(reportState => {
          this.closeForm(data);
        });
      } else {
        this.ohnApi.setElementStateSc(this.parentElement.report_element.element_slug, {value: slugs }, this.user.smart_contract).subscribe(reportState => {
            this.closeForm(data);
        });
      }
    } else {
      this.closeForm(data);
    }
   
  }

  closeForm(data? : any) {
    this.actionSub.next({
      action : 'close'
    });
  }

}
