import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-event-report',
  templateUrl: './survey-event-report.component.html',
  styleUrls: ['./survey-event-report.component.scss'],
})
export class SurveyEventReportComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
