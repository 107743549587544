import { Component, OnInit, Input } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-stats-chart',
  templateUrl: './stats-chart.component.html',
  styleUrls: ['./stats-chart.component.scss'],
})
export class StatsChartComponent  implements OnInit {

  @Input() renderOptions: EChartsOption;

  constructor() { }

  ngOnInit() {}

}
