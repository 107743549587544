import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-embed-element',
  templateUrl: './embed-element.component.html',
  styleUrls: ['./embed-element.component.scss'],
})
export class EmbedElementComponent  implements OnInit {

  @Input() el: OHNElement;

  objectUrl: SafeResourceUrl;
  validityRules : any[];

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.objectUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.el.image_url);   
    this.validityRules = (this.el.config && this.el.config.validityRules) ? this.el.config.validityRules : [];
    this.el.valid = this.validityRules.length > 0 ? false : true; 
  }

}
