import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-numeric-pick-one',
  templateUrl: './numeric-pick-one.component.html',
  styleUrls: ['./numeric-pick-one.component.scss'],
})
export class NumericPickOneComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
