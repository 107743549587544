import { Component, OnInit, Input } from '@angular/core';
import { OHNCalendarEvent, OHNElement, OHNUser } from '../../../models/ohn-instances';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-survey-event-view',
  templateUrl: './survey-event-view.component.html',
  styleUrls: ['./survey-event-view.component.scss'],
})
export class SurveyEventViewComponent  implements OnInit {
  @Input() event : OHNCalendarEvent;
  @Input() user : OHNUser;
  @Input() inlineCommunicator : Subject<any>;

  constructor() { }

  ngOnInit() {}

}
