<ng-container *ngIf="!loading.show">
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>{{formElement.text}}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="closeForm()">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <app-basic-elements-renderer 
      [elements]="formElement.elements" 
      [user]="user" >
    </app-basic-elements-renderer>
  </ion-content>
  <ion-footer>
    <ion-toolbar color="primary">
      <ion-button (click)="savePageState()" expand="full" fill="clear" color="light">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        Save Edits
      </ion-button>
    </ion-toolbar>
  </ion-footer>
</ng-container>

<app-loading-animation *ngIf="loading.show" 
  [aniType]="loading.type" 
  [aniText]="loading.text"
></app-loading-animation>