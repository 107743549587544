<!--<ion-item lines="none" class="ion-text-wrap">
  <ion-input type="text"
   [(ngModel)]="el.value" 
   (ionChange)="changed()" 
   name="inp_{{el.element_slug}}"></ion-input>
</ion-item>-->

<h4>{{el.text}}</h4>
<ion-input 
  [(ngModel)] = "el.value" 
  (ionChange) = "changed()" 
  name = "inp_{{el.element_slug}}" 
  aria-label = "el.text"
  fill="solid"
></ion-input>
