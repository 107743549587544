<app-loading-animation *ngIf="loading.show" 
  [aniType]="loading.type" 
></app-loading-animation>

<ng-container *ngIf="!loading.show">
  
  <ng-container [ngSwitch]="el.element_slug">

    <ng-container *ngSwitchCase="'pain_plan_goals_content'">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>{{el.text}}</ion-title>
          <ion-buttons slot="end">        
            <ion-button (click)="closeComponent()">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>           
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item *ngFor="let item of itemsList.content; let i = index;">
          <ion-label>
            <h2><strong>{{item.text}}</strong></h2>
            <p>{{item.description}}</p>
          </ion-label>
          <ion-button (click)="removeItem(i)" slot="end" fill="clear">
            <ion-icon name="close" slot="icon-only" color="medium"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-content>

      <ion-footer>
        <ion-toolbar color="primary">
          <!--<ion-buttons slot="end">-->
            <ion-button id="goalModal" expand="full" fill="clear" color="light">
              <ion-icon name="add" slot="start"></ion-icon>
              New Goal
            </ion-button>
          <!--</ion-buttons>
          <ion-buttons slot="end">
            <ion-button (click)="updateList()">
              <ion-icon name="checkmark" slot="start"></ion-icon>
              {{btnState}}
            </ion-button>
          </ion-buttons>
          -->
        </ion-toolbar>
      </ion-footer>

      <ion-modal #newGoal trigger="goalModal" [initialBreakpoint]="0.5" [breakpoints]="[0, 0.5, 0.75]" mode="ios">
        <ng-template>
          <ion-content>
            <!--<ion-searchbar placeholder="Search" (click)="modal.setCurrentBreakpoint(0.75)"></ion-searchbar>-->
            <ion-list>
              <ion-list-header>
                <ion-label>New Goal:</ion-label>
              </ion-list-header>
              <ion-item>
                <ion-input [(ngModel)]="newItem.text" label="Title:" labelPlacement="fixed" placeholder="Enter goal title here" [clearInput]="true"></ion-input>
              </ion-item>
              <ion-item>
                <ion-textarea [(ngModel)]="newItem.description" label="Description:" labelPlacement="fixed" placeholder="Enter here"></ion-textarea>
              </ion-item>
            </ion-list>
            <div class="ion-padding">
              <ion-button (click)="addItem()" expand="block">
                <ion-icon name="checkmark" slot="start"></ion-icon>
                Add Goal
              </ion-button>
            </div>
          </ion-content>
        </ng-template>
      </ion-modal>

    </ng-container>

    <ng-container *ngSwitchCase="'pain_plan_tools_content'">      
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>{{el.text}}</ion-title>
          <ion-buttons slot="end">        
            <ion-button (click)="closeComponent()">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>           
        </ion-toolbar>
        <ion-toolbar color="primary">
          <ion-searchbar [(ngModel)]="itemSearch" mode="ios"></ion-searchbar>
          <!--
          <ion-segment [(ngModel)]="selectedToolsCategory" [scrollable]="true">
            <ion-segment-button *ngFor="let category of el.elements" [value]="category.element_slug">
              <ion-label>{{category.text}}</ion-label>
            </ion-segment-button>
          </ion-segment>
          -->
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item *ngFor="let item of itemsList.content | filterBy:['text']:itemSearch">
          <ion-label>
            <h2><strong>{{item.text}}</strong></h2>
            <p><a href="{{item.url}}" target="_blank">{{item.url}}</a></p>
          </ion-label>
        </ion-item>
      </ion-content>
    </ng-container>

    <ng-container *ngSwitchCase="'pain_plan_care_team_content'">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>{{el.text}}</ion-title>
          <ion-buttons slot="end">        
            <ion-button (click)="closeComponent()">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>           
        </ion-toolbar>
        <ion-toolbar color="primary">
          <ion-segment [(ngModel)]="selectedCareTeamCategory" [scrollable]="true">
            <ion-segment-button *ngFor="let category of el.elements" [value]="category.element_slug">
              <ion-label>{{category.text}}</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ng-container *ngIf="selectedCareTeamCategory == 'emergency-numbers-60225'">
          <ion-item>
            <ion-label>
              <p><a href="tel:911" target="_blank">911</a></p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <h2><strong>Suicide and Crisis Lifeline</strong></h2>
              <p><a href="tel:988" target="_blank">988</a></p>
            </ion-label>
          </ion-item>
        </ng-container>
        <ng-container *ngFor="let cItem of itemsList.content; let i = index;">
        <ion-item *ngFor="let item of [cItem] | filterByImpure:['category']:selectedCareTeamCategory;">
          <ion-label>
            <h2><strong>{{item.text}}</strong></h2>
            <p><a href="tel:{{item.url}}" target="_blank">{{item.url}}</a></p>
            <p>{{item.description}}</p>
          </ion-label>
          <ion-button (click)="removeItem(i)" slot="end" fill="clear">
            <ion-icon name="close" slot="icon-only" color="medium"></ion-icon>
          </ion-button>
        </ion-item>
      </ng-container>
      </ion-content>
      <ion-footer>
        <ion-toolbar color="primary">
          <!--<ion-buttons slot="start">-->
            <ion-button id="contactModal" expand="full" fill="clear" color="light">
              <ion-icon name="add" slot="start"></ion-icon>
              New Contact
            </ion-button>
          <!--</ion-buttons>
          <ion-buttons slot="end">
            <ion-button (click)="updateList()">
              <ion-icon name="checkmark" slot="start"></ion-icon>
              {{btnState}}
            </ion-button>
          </ion-buttons>-->
        </ion-toolbar>
      </ion-footer>

      <ion-modal #newContact trigger="contactModal" [initialBreakpoint]="0.5" [breakpoints]="[0, 0.5, 0.75]" mode="ios">
        <ng-template>
          <ion-content>
            <!--<ion-searchbar placeholder="Search" (click)="modal.setCurrentBreakpoint(0.75)"></ion-searchbar>-->
            <ion-list>
              <ion-list-header>
                <ion-label>New Contact:</ion-label>
              </ion-list-header>
              <ion-item>
                <ion-input [(ngModel)]="newItem.text" label="Name:" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
              </ion-item>
              <ion-item>
                <ion-input [(ngModel)]="newItem.url" type="phone" label="Phone:" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
              </ion-item>
              <ion-item>
                <ion-input [(ngModel)]="newItem.description" type="phone" label="Address:" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
              </ion-item>
            </ion-list>
            <div class="ion-padding">
              <ion-button (click)="addItem(selectedCareTeamCategory)" expand="block">
                <ion-icon name="checkmark" slot="start"></ion-icon>
                Add Contact
              </ion-button>
            </div>
          </ion-content>
        </ng-template>
      </ion-modal>
    </ng-container>

    <ng-container *ngSwitchCase="'pain_plan_medications_content'">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>{{el.text}}</ion-title>
          <ion-buttons slot="end">        
            <ion-button (click)="closeComponent()">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>           
        </ion-toolbar>
      </ion-header>
      <ion-content mode="ios">
        <ion-item lines="none">
          <ion-icon name="alert-circle-outline" slot="start"></ion-icon>
          <ion-label color="medium">Please fill in this section with your PCP</ion-label>
        </ion-item>

        <ion-item *ngFor="let item of itemsList.content; let i = index;">
          <ion-label>
            <h2><strong>{{item.text}}</strong></h2>
            <p><ion-text color="medium"><strong>{{item.date}}</strong></ion-text></p>
            <p>{{item.description}}</p>
          </ion-label>
          <ion-button (click)="removeItem(i)" slot="end" fill="clear">
            <ion-icon name="close" slot="icon-only" color="medium"></ion-icon>
          </ion-button>
        </ion-item>

      </ion-content>

      <ion-footer>
        <ion-toolbar color="primary">
          <!--<ion-buttons slot="start">-->
            <ion-button id="medicationModal" expand="full" fill="clear" color="light">
              <ion-icon name="add" slot="start"></ion-icon>
              New Medication
            </ion-button>
          <!--</ion-buttons>
          <ion-buttons slot="end">
            <ion-button (click)="updateList()">
              <ion-icon name="checkmark" slot="start"></ion-icon>
              {{btnState}}
            </ion-button>
          </ion-buttons>-->
        </ion-toolbar>
      </ion-footer>

      <ion-modal #newMedication trigger="medicationModal" [initialBreakpoint]="0.5" [breakpoints]="[0, 0.5, 0.75]" mode="ios">
        <ng-template>
          <ion-content>
            <!--<ion-searchbar placeholder="Search" (click)="modal.setCurrentBreakpoint(0.75)"></ion-searchbar>-->
            <ion-list>
              <ion-list-header>
                <ion-label>New Medication:</ion-label>
              </ion-list-header>
              <ion-item>
                <ion-input [(ngModel)]="newItem.text" label="Medication:" labelPlacement="fixed" placeholder="Enter medication name" [clearInput]="true"></ion-input>
              </ion-item>
              <ion-item>
                <ion-input [(ngModel)]="newItem.date" label="When to take:" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
              </ion-item>
              <ion-item>
                <ion-input [(ngModel)]="newItem.description" label="How to take:" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
              </ion-item>
            </ion-list>
            <div class="ion-padding">
              <ion-button (click)="addItem()" expand="block">
                <ion-icon name="checkmark" slot="start"></ion-icon>
                Add Medication
              </ion-button>
            </div>
          </ion-content>
        </ng-template>
      </ion-modal>
    </ng-container>

    <ng-container *ngSwitchCase="'pain_plan_flare_plan_content'">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>{{el.text}}</ion-title>
          <ion-buttons slot="end">        
            <ion-button (click)="closeComponent()">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>           
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item *ngFor="let item of itemsList.content; let i = index;">
          <ion-label>
            <h2><strong>{{item.text}}</strong></h2>
            <p>{{item.description}}</p>
            <!--<p><ion-text color="medium"><strong>{{item.url}}</strong></ion-text></p>-->
          </ion-label>
          <ion-button (click)="removeItem(i)" slot="end" fill="clear">
            <ion-icon name="close" slot="icon-only" color="medium"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-content>

      <ion-footer>
        <ion-toolbar color="primary">
          <!--<ion-buttons slot="start">-->
            <ion-button id="stepModal" expand="full" fill="clear" color="light">
              <ion-icon name="add" slot="start"></ion-icon>
              New Step
            </ion-button>
          <!--</ion-buttons>
          <ion-buttons slot="end">
            <ion-button (click)="updateList()">
              <ion-icon name="checkmark" slot="start"></ion-icon>
              {{btnState}}
            </ion-button>
          </ion-buttons>-->
        </ion-toolbar>
      </ion-footer>

      <ion-modal #newStep trigger="stepModal" [initialBreakpoint]="0.5" [breakpoints]="[0, 0.5, 0.75]" mode="ios">
        <ng-template>
          <ion-content>
            <!--<ion-searchbar placeholder="Search" (click)="modal.setCurrentBreakpoint(0.75)"></ion-searchbar>-->
            <ion-list>
              <ion-list-header>
                <ion-label>New Step:</ion-label>
              </ion-list-header>
              <ion-item>
                <ion-input [(ngModel)]="newItem.text" label="Step:" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
              </ion-item>
              <ion-item>
                <ion-textarea [(ngModel)]="newItem.description" label="Description:" labelPlacement="fixed" placeholder="Enter here"></ion-textarea>
              </ion-item>
              <!--
              <ion-item>
                <ion-input [(ngModel)]="newItem.description" label="Emergency:" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
              </ion-item>
              <ion-item>
                <ion-input [(ngModel)]="newItem.url" label="Contact:" labelPlacement="fixed" placeholder="Enter here" [clearInput]="true"></ion-input>
              </ion-item>
              -->
            </ion-list>
            <div class="ion-padding">
              <ion-button (click)="addItem()" expand="block">
                <ion-icon name="checkmark" slot="start"></ion-icon>
                Add Step
              </ion-button>
            </div>
          </ion-content>
        </ng-template>
      </ion-modal>
    </ng-container>

    <ng-container *ngSwitchCase="'pain_plan_other_content'">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>{{el.text}}</ion-title>
          <ion-buttons slot="end">        
            <ion-button (click)="closeComponent()">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>           
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item *ngFor="let item of itemsList.content; let i = index;">
          <ion-label>
            <p>{{item.text}}</p>
          </ion-label>
          <ion-button (click)="removeItem(i)" slot="end" fill="clear">
            <ion-icon name="close" slot="icon-only" color="medium"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-content>

      <ion-footer>
        <ion-toolbar color="primary">
          <!--<ion-buttons slot="start">-->
            <ion-button id="noteModal" expand="full" fill="clear" color="light">
              <ion-icon name="add" slot="start"></ion-icon>
              New Note
            </ion-button>
          <!--</ion-buttons>
          <ion-buttons slot="end">
            <ion-button (click)="updateList()">
              <ion-icon name="checkmark" slot="start"></ion-icon>
              {{btnState}}
            </ion-button>
          </ion-buttons>-->
        </ion-toolbar>
      </ion-footer>

      <ion-modal #newNote trigger="noteModal" [initialBreakpoint]="0.5" [breakpoints]="[0, 0.5, 0.75]" mode="ios">
        <ng-template>
          <ion-content>
            <ion-list>
              <ion-list-header>
                <ion-label>New Note:</ion-label>
              </ion-list-header>
              <ion-item>
                <ion-textarea [(ngModel)]="newItem.text" placeholder="Enter here"></ion-textarea>
              </ion-item>
            </ion-list>
            <div class="ion-padding">
              <ion-button (click)="addItem()" expand="block">
                <ion-icon name="checkmark" slot="start"></ion-icon>
                Add Note
              </ion-button>
            </div>
          </ion-content>
        </ng-template>
      </ion-modal>
    </ng-container>

<!--
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>{{el.text}}</ion-title>
      <ion-buttons slot="end">        
        <ion-button (click)="closeComponent()">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>           
    </ion-toolbar>
    <ion-toolbar *ngIf="el.controller == 'itemListSortableController'" color="primary">
      <ion-segment [(ngModel)]="selectedCategory" [scrollable]="true">
        <ion-segment-button *ngFor="let category of el.elements" [value]="category.element_slug">
          <ion-label>{{category.text}}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
  </ion-header>
  <ion-content>
        <ion-item *ngFor="let item of itemsList.content; let i = index;">
          <ion-label><h2>{{item.text}}</h2></ion-label>
          <ion-button (click)="removeItem(i)" slot="end" fill="clear">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-item>
      </ng-container>

      <ng-container *ngSwitchCase="'itemListSortableController'">
        <ion-item *ngFor="let item of itemsList.content; let i = index;">
          <ion-label>
            <h2>{{item.text}}</h2>
            <p><a href="{{item.url}}" target="_blank">{{item.url}}</a></p>
          </ion-label>
        </ion-item>
      </ng-container>

    </ng-container>
  </ion-content>

  <ng-container *ngIf="el.controller == 'itemListEditableController'">
    <ion-footer class="ion-padding">
        <ion-input [(ngModel)]="newItem.text" placeholder="Enter here" fill="outline"></ion-input>
        <ion-button (click)="addItem()" expand="block" mode="ios">Add</ion-button>
    </ion-footer>
  </ng-container>
-->
</ng-container>
</ng-container>
