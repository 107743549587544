import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-audio-element',
  templateUrl: './audio-element.component.html',
  styleUrls: ['./audio-element.component.scss'],
})
export class AudioElementComponent  implements OnInit {

  @Input() el: OHNElement;

  videoUrl: SafeResourceUrl;

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.el.valid = true;
    this.videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.el.video_url);
  }

}
