import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-audio-embed-element',
  templateUrl: './audio-embed-element.component.html',
  styleUrls: ['./audio-embed-element.component.scss'],
})
export class AudioEmbedElementComponent  implements OnInit {

  @Input() el: OHNElement;

  videoUrl: SafeResourceUrl;
  customControls: any = {
    btnPlay: 'play',
    btnMute: 'volume-mute'
  };
  audioCurrentTime: number;
  audioDuration: number;


  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  public YT: any;
  public YTVideoID: any;
  public player: any;
  

  ngOnInit() {
    this.YTVideoID = this.getYTVideoID(this.el.video_url);;
    this.loadYTIframeAPI();
  }

  getYTVideoID(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return "";
    }
  }
  
  loadYTIframeAPI() {
    // Return if Player is already created
    if (window['YT']) {
      this.startVideo();
      return;
    }
    
    var tag = document.createElement('script');
    tag.src = 'http://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    /* 3. startVideo() will create an <iframe> (and YouTube player) after the API code downloads. */
    window['onYouTubeIframeAPIReady'] = () => this.startVideo();
  }
  
  startVideo() {
    this.player = new window['YT'].Player('YTAudio', {

      videoId: this.YTVideoID,
      playerVars: {
        playsinline: 1
      },
      events: {
        'onStateChange': this.onPlayerStateChange.bind(this),
        'onError': this.onPlayerError.bind(this),
        'onReady': this.onPlayerReady.bind(this),
      }
    });
    this.customControls.btnPlay = 'play';
    this.audioCurrentTime = this.player.getCurrentTime();
    this.audioDuration = this.player.getDuration();
  }

  toggleAudio() {
    if (this.player.getPlayerState() == 1) { 
      this.player.pauseVideo();
      this.customControls.btnPlay = 'play';
    } else {
      this.player.playVideo();
      this.customControls.btnPlay = 'pause';
    }
  }

  toggleSound() {
    (this.customControls.btnMute == 'volume-medium') ? this.customControls.btnMute = 'volume-mute' : this.customControls.btnMute = 'volume-medium';
  }
  
  /* 4. It will be called when the Video Player is ready */
  onPlayerReady(event) {
    console.log('onPlayerReady', this.customControls.btnPlay);
    //event.target.playVideo();
  }
  
  /* 5. API will call this function when Player State changes like PLAYING, PAUSED, ENDED */
  onPlayerStateChange(event) {
    switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
        this.customControls.btnPlay = 'pause';
        if (this.cleanTime() == 0) {
          console.log('started ' + this.cleanTime());
        } else {
          console.log('playing ' + this.cleanTime())
        };
        console.log('changes PLAYING', this.customControls.btnPlay);
        break;
      case window['YT'].PlayerState.BUFFERING:
        this.customControls.btnPlay = 'play';
        console.log('changes BUFFERING', this.customControls.btnPlay);
        break;
      case window['YT'].PlayerState.CUED:
        this.customControls.btnPlay = 'play';
        console.log('changes CUED', this.customControls.btnPlay);
        break;
      case window['YT'].PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
          console.log('paused' + ' @ ' + this.cleanTime());
        this.customControls.btnPlay = 'play';
        console.log('changes PAUSED', this.customControls.btnPlay);
        };
        break;
      case window['YT'].PlayerState.ENDED:
        this.customControls.btnPlay = 'play';
        console.log('ended ');
        console.log('changes ENDED', this.customControls.btnPlay);
        break;
    }
  }
  
  cleanTime() {
    return Math.round(this.player.getCurrentTime())
  }

  onPlayerError(event) {
    switch (event.data) {
      case 2:
        console.log('' + this.YTVideoID)
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  }

}
