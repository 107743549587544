<div class="ohn-panel">
  <div class="ohn-panel-header">
    <ion-toolbar>
      <ion-title>{{event.title}}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="closeInlineComponent()">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <div class="ohn-panel-content ion-padding">
        <ion-row>
          <ion-col>
            <h3>Medication name :</h3>
          </ion-col>
          <ion-col>
            <h3>{{event.title}}</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h3>Description : </h3>
          </ion-col>
          <ion-col>
            <h3>{{event.description}}</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h3>Dose : </h3>
          </ion-col>
          <ion-col>
            <h3>{{event.contentObject.dose}}</h3>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <h3>Intake Time : </h3>
          </ion-col>
          <ion-col>
            <h3>{{event.startTime | date:'EEEE, MMMM d, y, h:mm:ss a'}}</h3>
          </ion-col>
        </ion-row>
          </div>

  <div class="ohn-panel-footer">
    <ion-button (click)="saveCompletion()" mode="ios" expand="block">
      <ion-icon name="checkmark" slot="start"></ion-icon>
      Take
    </ion-button>
  </div>
</div>