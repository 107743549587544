import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-numeric-field-element',
  templateUrl: './numeric-field-element.component.html',
  styleUrls: ['./numeric-field-element.component.scss'],
})
export class NumericFieldElementComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
