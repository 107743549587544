import { Component, OnInit, Input } from '@angular/core';
import { OhnApiService } from '../../api/ohn-api.service';
import { OhnHealthApiService } from '../../api/ohn-health-api.service';
import { Subject} from 'rxjs';
import { OHNElement, OHNUser} from '../../models/ohn-instances';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-device-integrations',
  templateUrl: './device-integrations.component.html',
  styleUrls: ['./device-integrations.component.scss'],
})
export class DeviceIntegrationsComponent  implements OnInit {

  @Input() mini: boolean;
  @Input() user: OHNUser;
  @Input() app: any;

  iHealthConnected : boolean = false;

  ihealthCommunicator : Subject<any> = new Subject();

  showDeviceModal : boolean = false;

  showiHealthButtons : boolean = false;

  syncURL : string;

  fitbitSynced : boolean = false;

  syncing : boolean = false;

  constructor(
    private ohnApi: OhnApiService,
    private ohnHealthApiService: OhnHealthApiService,
  ) { }

  ngOnInit() {
    this.getSyncURL();
    this.ihealthCommunicator.subscribe(state=>{
      if (state) {
        this.iHealthConnected = true
      }
    })

    if (localStorage.getItem('ohn-ihealth-authorized')) {
      this.iHealthConnected = true
    }
 
    if (Capacitor.getPlatform() === 'ios') {
      this.showiHealthButtons = true
    }
  }

  initHealthKit() {
    this.ohnHealthApiService.initHealthKit(this.app, this.user, this.ihealthCommunicator);
  }

  syncHealthKit() {
    this.ohnHealthApiService.getLatestSyncDates(this.user);
  }

  getSyncURL(){
    this.ohnApi.getFitbitSyncURL(this.user.smart_contract).subscribe(url => {
      this.fitbitSynced = url.oauth_ready;
      this.syncURL = url.oauth_url;
    })
  }

  syncFitbitData(){
    this.syncing = true;
    this.ohnApi.syncFitbitData().subscribe(data => {
      this.syncing = false;
    });
  }

}
