import { Component, OnInit, Input } from '@angular/core';
import { OHNCalendarEvent, OHNElement, OHNUser } from '../../../models/ohn-instances';
import { Subject } from 'rxjs';
import { OhnApiService } from '../../../api/ohn-api.service';

@Component({
  selector: 'app-meeting-event-view',
  templateUrl: './meeting-event-view.component.html',
  styleUrls: ['./meeting-event-view.component.scss'],
})
export class MeetingEventViewComponent  implements OnInit {

  @Input() calendarSlug : string;
  @Input() event : OHNCalendarEvent;
  @Input() inlineCommunicator : Subject<any>;
  @Input() user : OHNUser;

  constructor(
    private ohnApi: OhnApiService
  ) { }

  ngOnInit() {}

  saveCompletion() {
    this.event.completed = true;
    let patchObject: any = (({ id, completed, report_element_state_id, startTime, nylas_event_id, nylas_calendar_id }) => ({ id, completed, report_element_state_id, startTime, nylas_event_id, nylas_calendar_id }))(this.event);
    patchObject['completedTime'] = new Date();
    this.ohnApi.patchElementStateSc(this.calendarSlug, {value: patchObject}, this.user.smart_contract).subscribe(state => {
      this.closeInlineComponent();
    });
  }

  closeInlineComponent() {
    this.inlineCommunicator.next({
      action : 'close'
    });
  }

}
