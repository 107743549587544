import { Component, OnInit, ViewChild } from '@angular/core';
import { OhnApiService } from '../../api/ohn-api.service';
import { OHNElement } from '../../models/ohn-instances';
import { IonTabs } from '@ionic/angular';

@Component({
  selector: 'app-tabs-page',
  templateUrl: './tabs-page.component.html',
  styleUrls: ['./tabs-page.component.scss'],
})
export class TabsPageComponent  implements OnInit {

  @ViewChild('bottomTabs', {static: true}) tabRef: IonTabs;

  parentElement: OHNElement;

  constructor(
    private ohnApi: OhnApiService,
  ) { }

  ngOnInit() {
    this.loadParentElement()
  }

  loadParentElement() {
    this.ohnApi.getElement('tabs_patient', 2).subscribe(parentElement => {
      this.parentElement = parentElement;
      if (this.parentElement.elements[0]) {
        this.switchTab(this.parentElement.elements[0]);
      }
    });
  }

  switchTab(ohnPage: OHNElement) {
    this.tabRef.select(ohnPage.controller + '/' + ohnPage.element_slug);
  }

  getSelectedTab() {
    return this.tabRef.getSelected();
  }

}
