import { Component, OnInit, Input } from '@angular/core';
import { OhnLanguageService } from '../../services/ohn-language.service';

@Component({
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss'],
})
export class LoadingAnimationComponent implements OnInit {

  @Input() aniType: string; // "skeleton", "ring", "triad"
  @Input() aniText: string;

  constructor(
    public lS: OhnLanguageService,
  ) { }

  ngOnInit() {}
 
}
