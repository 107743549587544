<ng-container *ngIf="mini"> 
  <div (click)="showDeviceModal = true;" class="mini-view">      
    <ion-row>
      <ion-col>
        <img alt="Sync Device" src="assets/art/device.svg">
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
      <ion-button fill="clear" size="small">
        <ion-icon name="sync" slot="start"></ion-icon>
        My Devices
      </ion-button>
    </ion-col>
    </ion-row>
  </div>
</ng-container>

<ion-modal [isOpen]="showDeviceModal" mode="md">
  <ng-template>      
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Device Integrations</ion-title>
        <ion-buttons slot="end">        
          <ion-button (click)="showDeviceModal = false;">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>   
      </ion-toolbar>
    </ion-header>
    <ion-content mode="ios">
      <ion-item *ngIf="user && syncURL && !fitbitSynced" href="{{syncURL}}" target="_system" button>
        <ion-icon name="sync-outline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap"><h2>Enable FitBit Data Sync</h2></ion-label>
      </ion-item>
      <ion-item *ngIf="user && fitbitSynced" (click)="syncFitbitData()" [disabled]="syncing">
        <ion-icon name="sync-outline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap"><h2>Sync Fitbit Data</h2></ion-label>
        <ion-spinner name="crescent" *ngIf="syncing"></ion-spinner>
      </ion-item>
      <ion-item *ngIf="!iHealthConnected && showiHealthButtons" (click)="initHealthKit()" button>
        <ion-icon name="link" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap"><h2>Connect iHealth</h2></ion-label>
      </ion-item>
      <ion-item *ngIf="iHealthConnected && showiHealthButtons" (click)="syncHealthKit()" button>
        <ion-icon name="sync-outline" slot="start"></ion-icon>
        <ion-label class="ion-text-wrap"><h2>Sync iHealth Data</h2></ion-label>
      </ion-item>
    </ion-content>
  </ng-template>
</ion-modal>