import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fitbit-heart-rate',
  templateUrl: './fitbit-heart-rate.component.html',
  styleUrls: ['./fitbit-heart-rate.component.scss'],
})
export class FitbitHeartRateComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
