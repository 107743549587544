import { Component, OnInit, Input } from '@angular/core';
import { formatDate } from '@angular/common';
import { Subject} from 'rxjs';

import { OhnApiService } from '../../api/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent  implements OnInit {

  //@Input() eventsSrc: (OHNCalendarEvent|OHNMedication|OHNMeeting)[];
  @Input() eventsSrc: any;
  @Input() eventEmitterSub : Subject<any>;
  @Input() user: any;

  displayDate: string;
  highlightedDates: any[] = [];
  event: any = {
    "contentObject": {
      "url": "https://openhealth.cc"
    },
    "description": "Test med",
    "endTime": "2023-12-08T21:00:00.000Z",
    "title": "Telehealth check-in",
    "startTime": "2023-12-08T09:00:00.000Z",
    "allDay": false,
    "completed": false,
    "controller": "medication"
  }

  constructor(
    private ohnApi: OhnApiService
  ) { }

  ngOnInit() {
    DEBUG_MODE && console.log("calendar init");
    this.displayDate = new Date().toISOString();
    console.log("this.displayDate",this.displayDate);

    for (const [key, value] of Object.entries(this.eventsSrc)) {
      DEBUG_MODE && console.log(this.eventsSrc[key]);

      //this.eventsSrc[key].map(event => event["contentObject"] = JSON.parse(event.content));

      this.highlightedDates.push({
        date: key, 
        textColor: 'var(--ion-color-secondary-contrast)',
        backgroundColor: 'var(--ion-color-secondary)'
      })
    }

    

    DEBUG_MODE && console.log("this.highlightedDates", this.highlightedDates);
    DEBUG_MODE && console.log("this.eventsSrc", this.eventsSrc);
  }

  ngOnChanges(changes) {
    DEBUG_MODE && console.log("calendar changed");
    if (changes.eventsSrc) {
      this.highlightedDates = [];
      DEBUG_MODE && console.log("eventsSrc changed", this.eventsSrc);
    //  if (this.eventsSrc.length) {
    if (Object.keys(this.eventsSrc).length !== 0) {
    for (const [key, value] of Object.entries(this.eventsSrc)) {
      DEBUG_MODE && console.log(this.eventsSrc[key]);

      //this.eventsSrc[key].map(event => event["contentObject"] = JSON.parse(event.content));

      this.highlightedDates.push({
        date: key, 
        textColor: 'var(--ion-color-secondary-contrast)',
        backgroundColor: 'var(--ion-color-secondary)'
      })
    }

        DEBUG_MODE && console.log("this.highlightedDates", this.highlightedDates);
      }
      this.displayDate = new Date().toISOString();
      this.processDateChange();
    }
  };


  processDateChange() {
    DEBUG_MODE && console.log("processDateChange", this.displayDate);
    this.eventEmitterSub.next({
      event : 'change',
      date: formatDate(new Date(this.displayDate), 'YYYY-MM-dd', 'en')
    });
  }

  scheduleEvent() {
    this.ohnApi.setElementStateSc("calendar_container", {value: this.event}, this.user.smart_contract).subscribe(event => {
      console.log("Generated",event);
      //this.loadCalendarState(this.calendar.selectedDate);
    });
  }

}
