import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-field-element',
  templateUrl: './date-field-element.component.html',
  styleUrls: ['./date-field-element.component.scss'],
})
export class DateFieldElementComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
