import { Component, OnInit, Input } from '@angular/core';
import { OHNElement, OHNUser, OHNCalendarEvent, OHNMedication, OHNMeeting } from '../../models/ohn-instances';

import { DEBUG_MODE } from '../../../environments/environment';

@Component({
  selector: 'app-agenda-event-report',
  templateUrl: './agenda-event-report.component.html',
  styleUrls: ['./agenda-event-report.component.scss'],
})
export class AgendaEventReportComponent  implements OnInit {
  
  @Input() event : OHNCalendarEvent|OHNMedication|OHNMeeting;
  @Input() calendarSlug : string;
  @Input() user : OHNUser;

  reportState : any;

  constructor() { }

  ngOnInit() {}

}
