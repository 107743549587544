import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LoadingController, AlertController, ModalController } from '@ionic/angular';
import { APP_SLUG } from '../../environments/environment';
import { OHNUser } from '../models/ohn-instances';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OhnService {

  loading : any;
  alert : any;
  loadings: any = {};

  static datePipe : DatePipe = new DatePipe('en-US');

  widgetRefresher: Subject<any> = new Subject();

  currentPageSubject: Subject<any> = new Subject();
  userToDelete: Subject<OHNUser> = new Subject<OHNUser>();

  constructor(
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
    private modalController: ModalController,
  ){

  }

  async startLoading(msg: string) {
    this.loading = await this.loadingCtrl.create({
      message: msg
    });
    await this.loading.present();
  }

  async stopLoading(){
    if (this.loading) {
      await this.loading.dismiss()
      .then(()=>{
        this.loading = null;
      })
      .catch((e: any) => console.log(e));
    }
  }

  showAlert(title: string, message: string) {
    this.presentAlert(title, message);
  }

  async presentAlert(title: string, message: string) {
    this.alert = await this.alertController.create({
      header: title,
      message: message,
      buttons: ['OK']
    });

    await this.alert.present();
  }

  switchCurrentPage(page: any) {
    this.currentPageSubject.next(page);
  }

  static getUniqueId(itemsArray : any[]) {
    const existingIds : string[] = itemsArray.map(i => i.id);
    let uniqueId : string = '';
    while (!uniqueId || existingIds.includes(uniqueId)) {
      uniqueId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
    return uniqueId;
  }

  appRoleModifier(role: string, withApp: boolean) {
    if (withApp) {
      return APP_SLUG + '_' + role;
    } else {
      return role.replace(/.*\_/g, '');
    }
  }

  numberToArray(n: number): number[] {
    return Array(n);
  }

  getObjectCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  hasRole(role: string, user : OHNUser) {
    return user.roles.indexOf(APP_SLUG + '_' + role) >= 0;
  }

  static getColorByRange(ranges: any[], value: number){
    let fitRange : any = ranges.find((r)=>{
      return  (value >= r.range.min && value <=r.range.max);
    });
    return fitRange ? fitRange.color : '#ffffff'
  }

  static getValueByRange(ranges: any[], value: number){
    let fitRange : any = ranges.find((r)=>{
      return  (value >= r.range.min && value <=r.range.max);
    });
    return fitRange ? fitRange.value : 0
  }

  static getStringDatesPeriodForCalendar(targetDate: Date, period: string, shift: number) {
    let startDate: Date;
    let endDate: Date;

    if (period == "current_month") {
      startDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), shift);
      endDate = new Date(targetDate.getFullYear(), targetDate.getMonth() + shift, 0);
    } else {
      let periodMultiplier : number = (period == 'day') && 1 || (period == 'week') && 7 || (period == 'month') && 30 || (period == 'year') && 365;

      startDate = new Date(targetDate.getTime() - (24 * 3600000 * periodMultiplier * shift));
      endDate = new Date(targetDate.getTime() + (24 * 3600000 * periodMultiplier * shift));
    }

    return [this.datePipe.transform(startDate, 'yyyy-MM-dd'), this.datePipe.transform(endDate, 'yyyy-MM-dd')];
  }

  static dayOfWeekNumToText(daynum: number) {
    return (daynum === 1 && 'Sunday') || (daynum === 2 && 'Monday') || (daynum === 3 && 'Tuesday') || (daynum === 4 && 'Wednesday') || (daynum === 5 && 'Thursday') || (daynum === 6 && 'Friday') || (daynum === 7 && 'Saturday');
  }

  static daylightSavingCoef(dt : Date) {
    let jan : Date = new Date(dt.getFullYear(), 0, 1);
    let jul : Date = new Date(dt.getFullYear(), 6, 1);
    let offset: number = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    return dt.getTimezoneOffset() < offset ? 1 : 0;
  }

}