<ng-container *ngIf="!loading.show">
  <ng-container *ngIf="mini"> 
    <div (click)="showDeviceModal = true;" class="mini-view">      
      <ion-row>
        <ion-col>
          <img alt="Device Statuses" src="assets/art/radio-outline.svg">
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button fill="clear" size="small">
            <ion-icon name="ellipse" color="{{circleState}}" slot="start"></ion-icon>
            Device Statuses
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </ng-container>

  <ng-container *ngIf="!mini"> 
    <ion-item (click)="showDeviceModal = true;" lines="full">
      <ion-icon name="ellipse" color="{{circleState}}" slot="start"></ion-icon>
      <ion-label>Device Statuses</ion-label>
    </ion-item>
  </ng-container>

  <ion-modal [isOpen]="showDeviceModal" mode="md">
    <ng-template>      
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>Device Statuses</ion-title>
          <ion-buttons slot="end">        
            <ion-button (click)="showDeviceModal = false;">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>   
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item *ngFor="let device of deviceList;" lines="full">
          <ion-label class="ion-text-wrap">
             <strong>{{device}}</strong> - {{devicesInfo[device]}}
          </ion-label>
        </ion-item>
      </ion-content>
    </ng-template>
  </ion-modal>
</ng-container>

<app-loading-animation *ngIf="loading.show" [aniText]="loading.text" [aniType]="loading.type"></app-loading-animation>