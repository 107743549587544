import { Component, OnInit, Input } from '@angular/core';
import { OhnLogicService } from '../../../services/ohn-logic.service';
import { OHNElement } from '../../../models/ohn-instances';

import { OhnLanguageService } from '../../../services/ohn-language.service';

@Component({
  selector: 'app-numeric-slider-element',
  templateUrl: './numeric-slider-element.component.html',
  styleUrls: ['./numeric-slider-element.component.scss'],
})
export class NumericSliderElementComponent  implements OnInit {

  @Input() el: OHNElement;

  validityRules : any[];
  rangeArray : number[]; 

  constructor(
    public lS: OhnLanguageService
  ) { }

  ngOnInit() {
    console.log("locale", this.lS.locale);
    this.rangeArray = Array(Number(this.el.config.max)).fill(1).map((x,i) => i+1);
    this.el.value = this.el.value || 0;
    this.el.valid = true;
  }

}
