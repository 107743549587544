<!--
<ion-row class="events-header">
  <ion-col class="events-header-title">
    <span>Today</span>
  </ion-col>
  <ion-col class="events-header-text" (click)="restoreCurrentDate();">
    <span>{{currentDate.date | date: 'EEEE'}}</span>
    <span class="events-header-text-date">{{currentDate.date | date: 'MMMM d'}}</span>
  </ion-col>
</ion-row>
-->
<!--<div>-->
  <!--
    <ion-button fill="clear" (click)="changePeriod(-1, 0)">
      <ion-icon name="play-back" slot="icon-only"></ion-icon>
    </ion-button>
    <ion-button fill="clear" (click)="changePeriod(1, 0)">
      <ion-icon name="play-forward" slot="icon-only"></ion-icon>
    </ion-button>
  -->
<!--    <ion-button fill="clear" (click)="changePeriod(0, -1)">
      <ion-icon name="chevron-back" slot="icon-only"></ion-icon>
    </ion-button>
    <ion-button fill="clear" (click)="changePeriod(0, 1)">
      <ion-icon name="chevron-forward" slot="icon-only"></ion-icon>
    </ion-button>

    <span>{{selectedDate.monthName}}, {{selectedDate.year}}</span>



</div>
<div class="calendar-days-of-week">
  <div class="day-of-week">Sun</div>
  <div class="day-of-week">Mon</div>
  <div class="day-of-week">Tue</div>
  <div class="day-of-week">Wed</div>
  <div class="day-of-week">Thu</div>
  <div class="day-of-week">Fri</div>
  <div class="day-of-week">Sat</div>
</div>

<div class="CalendarGrid">
  <div *ngFor="let item of [].constructor(firstDate.weekDay)"></div>
  <div *ngFor="let day of daysCollection; let i = index">
    <button (click)="selectDate(day)" class="calendar-date {{day.cssClass}}" [ngClass]="{'calendar-date-active': (selectedDate.day - 1) == i}">{{day.day}}</button>
  </div>
</div>-->

<ion-datetime mode="md" presentation="date" [(ngModel)]="displayDate" size="cover" (ionChange)="processDateChange()" [highlightedDates]="highlightedDates"></ion-datetime>

<!--<ion-button (click)="scheduleEvent()">Generate event</ion-button>-->