<ion-text [ngClass]="el.config.class">
  <!--<markdown [data]="el.value | textReplacements" (ready)="updateLinks()"></markdown>-->
  <markdown [data]="el.value" (ready)="updateLinks()"></markdown>
</ion-text>

<ion-modal [isOpen]="isLinkOpened">
  <ng-template>
    <ion-content>
      <app-branched-content-renderer
        [parentElementSlug] = "loadPageSlug" 
        [user] = "user" 
        [inlineView] = "true"
        [inlineCommunicator] = "innerCom">
      </app-branched-content-renderer>
    </ion-content>
  </ng-template>
</ion-modal>