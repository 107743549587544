import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noise-level-element',
  templateUrl: './noise-level-element.component.html',
  styleUrls: ['./noise-level-element.component.scss'],
})
export class NoiseLevelElementComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
