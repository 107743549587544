import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-height-input-element',
  templateUrl: './height-input-element.component.html',
  styleUrls: ['./height-input-element.component.scss'],
})
export class HeightInputElementComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
