export interface OHNElement {
	element_slug?: string,
	_cls : string,
	text: string,
	settings?: any,
	controller: string,
	value?: any,
	config?: any,
	elements?: OHNElement[],
	prescribed? : number,
	video_url? : string,
	image_url? : string,
	isVariable? : boolean,
	id? : string,
	report_element? : OHNElement,
	actions? : any,
	numericVal? : number,
	valid? : boolean,
	liked? : boolean,
	counter? : number
}

export interface OHNBookmark {
	title? : string,
	pageSlug : string,
	category? : string,
	text? : string,
	tags?: string[]
}

export interface OHNUser {
	username: string,
	name: string,
	email: string,
	phone: string,
	smart_contract: string,
	profiles: any,
  roles: string[],
  app_slug: string,
  onboarded_at? : string | Date,
  order?: number,
  timezone? : number,
  elements? : any,
  projects_authorized? : string[]
}

export interface OHNExternalDevice {
	device_uuid? : string
  device_nickname? : string
  device_manufacturer : string
  device_model? : string
}

export interface OHNUserRole {
	id? : string,
	name : string,
	title? : string,
	permissions : string[],
	app_slug? : string,
	dependants? : string[],
	roleProfileElements? : OHNElement[],
	flag? : boolean
}

export class OHNLoading {
	show: boolean;
	type?: string;
	text?: string;

	constructor(show: boolean = false, type: string = '', text: string = '') {
		this.show = show;
		this.type = type;
		this.text = text;
	}
}

export class OHNLogicNode {
	id : string;
	text : string;
	previousNodeId : string;
	transitions : OHNLogicTransition[];

	constructor(node: any) {
		this.id = node.id;
		this.text = node.text;
		this.transitions = [];
		this.previousNodeId = '';
		node.transitions.forEach(t => {
			this.transitions.push(new OHNLogicTransition(t));
		});
  }
}

export class OHNLogicTransition {
	operator : string;
	type : string;
	operand : any;
	formula : string;
	compareToNode : string;
	transitions : OHNLogicTransition[];
	nextNode : string;
	reserveNodeId : string;

	constructor(transition: any) {
		this.operator = transition.operator;
		this.type = transition.type;
		this.operand = transition.operand;
		this.formula = transition.formula;
		this.compareToNode = transition.compareToNode;
		this.nextNode = transition.nextNode;
		this.transitions = [];
		this.reserveNodeId = transition.reserveNodeId;
		transition.transitions.forEach(t => {
			this.transitions.push(new OHNLogicTransition(t));
		});
  }
}

export class OHNLogicTree {
	id?: string;
	text : string;
	nodes : OHNLogicNode[];

	constructor(tree: any) {

		this.text = tree.text;
		this.nodes = [];
		tree.nodes.forEach(n => {
			this.nodes.push(new OHNLogicNode(n));
		});
	}
}

export class OHNCalendarDate {
	date: Date;
  day: number;
  weekDay: number;
  month: number;
  monthName: string;
  year: number;
  cssClass: string;

	constructor(d: Date = new Date(), locale: string = 'en-US', c: string = 'ohn-calendar-date') { 
		this.date = d;
		this.day = this.date.getDate();
		this.weekDay = this.date.getDay();
		this.month = this.date.getMonth();
		this.monthName = new Intl.DateTimeFormat(locale, { month: 'long'}).format(this.date);
		this.year = this.date.getFullYear();
		this.cssClass = c;
	}
}

export class OHNCalendarEvent {
	id : string;
	title: string;
	startTime: string | Date;
	endTime: string | Date;
	description: string;
	element_slug: string;
	completed: boolean;
	allDay: boolean;
	content?: string;
	rating: number;
	controller: string;
	contentObject?: any;
	inner_element_slug? : string;
  inner_element_state_id? : string;
  report_element_slug? : string;
  report_element_state_id? : string;
  nylas_calendar_id? :string;
  nylas_event_id?: string;
  rrule? : string; 

	constructor(event: any = {
		id : '',
		title : '',
		description : '',
		startTime : new Date(),
		endTime : new Date(),
		element_slug : '',
		completed : false,
		allDay: false,
		rating: 0,
		controller: 'exercise',
		content: '{}',
		inner_element_slug : '',
    inner_element_state_id : '',
    report_element_slug : '',
    report_element_state_id : '',
    nylas_event_id : '',
    nylas_calendar_id : '',
    rrule : ''
	}, useDateOnly : boolean = false) {
      this.id = event.id;
      this.title = event.title;
      this.description = event.description;
      if (useDateOnly) {
      	this.startTime = new Date(event.startTime);
      	this.endTime = new Date(event.endTime);
      } else {
      	//this.startTime = new Date(event.startTime);
      	//this.endTime = new Date(event.endTime);
      	this.startTime = event.startTime;
      	this.endTime = event.endTime;
      }
      this.element_slug = event.element_slug;
      this.completed = event.completed,
      this.allDay = event.allDay,
      this.content = event.content;
      this.rating = event.rating;
      this.controller = event.controller;
      this.contentObject = this.content && this.content != '{}' && this.content.charAt(0) == "{" ? JSON.parse(this.content) :  <OHNCalendarActionList>{
	      text : "",
	      icon: "",
	      description : [],
	      actions : []
	    };
	    this.inner_element_slug = event.inner_element_slug,
    	this.inner_element_state_id = event.inner_element_state_id,
    	this.report_element_slug = event.report_element_slug,
    	this.report_element_state_id = event.report_element_state_id,
    	this.rrule = event.rrule,
    	this.nylas_calendar_id = event.nylas_calendar_id,
    	this.nylas_event_id = event.nylas_event_id
  }

	normalizeTimes(): void {
		this.startTime = new Date(this.startTime),
		this.endTime = new Date(this.endTime)
	};

	mergeDateAndTimes(date: Date, startTime: Date, endTime: Date): void {
		this.endTime = new Date(date);
  	this.endTime.setHours(endTime.getHours(), endTime.getMinutes());
  	this.startTime = new Date(date);
  	this.startTime.setHours(startTime.getHours(), startTime.getMinutes());
	}
}

export class OHNMedication extends OHNCalendarEvent {

	cron: any;
	post_set_state_trigger: any[];

	constructor(event: any = {
		id : '',
		title : '',
		description : '',
		startTime : new Date(),
		endTime : new Date(),
		element_slug : '',
		completed : false,
		allDay: false,
		rating: 0,
		content: '{}',
		controller: 'medication',
		cron: {},
		post_set_state_trigger : []
	}, useDateOnly: boolean = false, onlyStartTime: boolean = false, singleEvent: boolean = false) {
    super (event, useDateOnly);
   	if (useDateOnly && onlyStartTime) {
			let userTimezoneOffset = new Date(event.startTime).getTimezoneOffset() * 60000;
			this.startTime = new Date((new Date(event.startTime).getTime() + userTimezoneOffset));

    	let eD: number = this.startTime.getTime() + 3600000;
    	this.endTime = new Date(eD);
    }
    this.contentObject = this.content && this.content != '{}' ? JSON.parse(this.content) :  {
      dose : ""
    };
    if (!singleEvent) {
    	this.post_set_state_trigger = [
	    	{
	        action:'setup_periodic_get_state_for_calendar'
	      }
	    ]
	  }
    this.cron = event.cron;
  }

  formatCron(weekDays: boolean[], intakeTimes: any[]): void {

		let weekday: number[] = [];

    weekDays.forEach((d,i)=>{
      if (d) {
      	weekday.push(i)
      }
    });

    this.cron = {
      hour : intakeTimes.map(intake => { return new Date(intake.value).getHours()}),
      weekday : weekday,
      minute : intakeTimes.map(intake => { return new Date(intake.value).getMinutes()})
    }

	};
}

export class OHNMeeting extends OHNMedication {

	override cron: any;

	constructor(event: any = {
		id : '',
		title : '',
		description : '',
		startTime : new Date(),
		endTime : new Date(),
		element_slug : '',
		completed : false,
		allDay: false,
		rating: 0,
		content: '{}',
		controller: 'meeting',
		cron: {}
	}, useDateOnly: boolean = false, onlyStartTime: boolean = false, singleEvent: boolean = false) {
    super (event, useDateOnly, onlyStartTime, singleEvent);
  }
	
}

export class OHNCalendarAction {
	text : string;
	description: OHNElement[];
	done : boolean;
	trackingParameters : OHNElement[];
	repeat : number;
	id? : string;
}

export class OHNCalendarActionList {
	id? : string;
	text : string;
	icon: string;
	type: string;
	description : OHNElement[];
	actions : OHNCalendarAction[];

	constructor(id: string) {
		this.id = id;
		this.text = '';
	  this.icon = '';
	  this.type = '';
	  this.description = [];
	  this.actions = [];
  }
}

export class OHNContentItem {
	text : string;
	url? : string;
	category?: string;
	description?: string;
	date? : string | Date;

	constructor() {
		this.text = '';
  }
}

export class OHNContentItemList {
	content : OHNContentItem[];

	constructor() {
		this.content = [];
  }
}

export class OHNEventPreset {
	title : string;
	id? : string;
	description? : string;
	contentObject : any;
	completed?: boolean;
	allDay?: boolean;
	controller?: string;
	startTime?: string | Date;
	endTime?: string | Date;
	inner_element_slug?: string;
	image_url?: string;
	text?: string;
  supplementary_action?: OHNCalendarItemAction;

	constructor(event: any = {
		title : '',
		description : '',
		startTime : new Date(),
		completed : false,
		allDay: false,
		controller: 'exercise',
		contentObject: {},
    supplementary_action: {
      action_name: "send_email_notification",
      parameters: {
          to: "",
          subject: "",
          text: ""
      },
      datetime: ""
    }
	}) {
    this.title = event.title;
    this.description = event.description;
    this.startTime = new Date(event.startTime);
    this.completed = event.completed;
    this.allDay = event.allDay;
    this.controller = event.controller;
    this.contentObject = event.contentObject;
		this.supplementary_action = event.supplementary_action;
	}
}

export interface OHNCalendarItemAction {
	action_name : string;
	parameters : any;
	datetime : string | Date;
}

export class OHNCalendarItem {
	id : string;
	title: string;
	startTime: string | Date;
	endTime: string | Date;
	description: string;
	element_slug: string;
	completed: boolean;
	allDay: boolean;
	controller: string;
	contentObject?: any;
	inner_element_slug? : string;
  inner_element_state_id? : string;
  report_element_slug? : string;
  report_element_state_id? : string;
  supplementary_action?: OHNCalendarItemAction;

	constructor(event: any = {
		id : '',
		title : '',
		description : '',
		startTime : new Date(),
		endTime : new Date(),
		element_slug : '',
		completed : false,
		allDay: false,
		controller: 'exercise',
		contentObject: {},
		inner_element_slug : '',
    inner_element_state_id : '',
    report_element_slug : '',
    report_element_state_id : '',
    supplementary_action: {
      action_name: "send_email_notification",
      parameters: {
          to: "",
          subject: "",
          text: ""
      },
      datetime: ""
    }
	}) {
	  this.id = event.id;
    this.title = event.title;
    this.description = event.description;
    this.startTime = new Date(event.startTime);
    this.endTime = new Date(event.endTime);
    this.element_slug = event.element_slug;
    this.completed = event.completed;
    this.allDay = event.allDay;
    this.controller = event.controller;
    this.contentObject = event.contentObject;
    this.inner_element_slug = event.inner_element_slug;
  	this.inner_element_state_id = event.inner_element_state_id;
  	this.report_element_slug = event.report_element_slug;
  	this.report_element_state_id = event.report_element_state_id;
		this.supplementary_action = event.supplementary_action;
	}
}

export class OHNTableRow {
	cols_num: number;
	cols: any[];

	constructor(cols_num: number, vals?: string[]) {
		this.cols = [];

		for (let i = 0; i < cols_num; i++) {
			let col = {};
			//col[`col_${i}`] = (vals) ? vals[i] || '' : '';
			col['val'] = (vals) ? vals[i] || '' : '';
			this.cols.push(col);
		}
  }
}