import { Injectable } from '@angular/core';
import { Subject, Observable, throwError , empty, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment, API_URL, APP_SLUG, BASIC_HEADERS } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OhnLanguageService {

  languageChanger : Subject<string> = new Subject();
  languageLoader : Subject<boolean> = new Subject();

  lang: any;

  backupLang : any = {
    en : {
      language_name: 'English-Multicultural',
      locale: 'en',
      strings: {       
        APP_OVERVIEW : 'The purpose of this app is to help caregivers understand the functional stages of dementia and to provide links to resources that are helpful at each stage.',
        accept : 'Accept',
        addTime : 'Add Time',
        areYouSure : 'Are you sure?',
        areYouSureYouWantToLogOut : 'Are you sure you want to log out?',
        average : 'Average',
        beINSPIRED : 'Be INSPIRED',
        body : 'Body',
        bookmark : 'Bookmark',
        bookmarks : 'Bookmarks',
        bookmarkTag : 'Bookmark Tag',
        books : 'Books',
        bookSense : 'Book Sense',
        cadence : 'Cadence',
        cancel : 'Cancel',     
        center : 'Center',   
        click : 'click',
        close : 'Close',
        closing : 'Closing',
        community : 'Community',
        communityWisdom : 'Community Wisdom',
        completed : 'Completed',
        cognitiveIntervention : 'Cognitive Intervention',
        contactMedicalProvider : 'If you think you or someone you care for is having a medical or mental health emergency, call 911 or go to the nearest emergency room. Do not attempt to access emergency care through this website.',
        continue : 'Continue',
        continueYourINSPIREJourney : 'Continue Your INSPIRE Journey',
        currLang : 'Current Language',
        credentialsNotValid : 'Credentials are not valid',
        data : 'Data',
        dataRecord : 'Data Record',
        dataRecords : 'Data Records',
        date : 'Date',
        decline : 'Decline',
        delete : 'Delete',
        deleteEvent : 'Delete Event',
        deleteNote : 'Delete Note',
        description : 'Description',
        devicePaired : 'Device paired',
        disclaimer : 'Disclaimer',
        dismiss : 'Dismiss',
        done : 'Done',
        dose : 'Dose',
        downloadFitbitApp : 'Download Fitbit app here',
        doYouWantToSaveTheResult : 'Do you want to save the result?',
        editEvent : 'Edit Event',
        emailAddress : 'email address',
        emailPhoneRequired : 'Email Address or Phone Number required',
        enableBookmark : 'Enable Bookmark',
        enableFitbitDataSync : 'Enable Fitbit Data Sync',
        enjoymentOfLife : 'Enjoyment of Life',      
        enterAuthorizationCode : 'Enter authorization code',
        enterCodeYouReceived : 'Enter code you received',  
        enterPhoneEmail : 'Enter phone or email',
        enterYour : 'Enter your',
        enterYourEmail : 'Enter your email address',
        eventStartTime : 'Event Start Time',
        findMoreProfessionalSupport : 'Find More Professional Support',
        fitbitDataSyncEnabled : 'Fitbit Data Sync Enabled',
        forgetDevice : 'Forget Device',
        friend : 'Friend',
        GAD : 'GAD',
        GADScore : 'Depressive Symptoms Score',
        gear : 'Gear',
        generalActivity : 'General Activity',
        generatingVerificationCode : 'Generating verification code',
        goBack : 'Go Back',
        goodDay : 'Good Day!',
        goodEvening : 'Good Evening!',
        goodMorning : 'Good Morning!',
        hasPrescribedParameters : 'Has Prescribed Parameters',
        heartRate : 'Heart Rate',
        here : 'here',
        history : 'History',
        home: 'Home',
        imageAlignment : 'Image Alignment',
        imageAltText : 'Image Alt Text',
        imageUrl : 'Image URL',
        inspireJourneyProgress : 'INSPIRE Journey Progress',
        intakeTimes : 'Intake Times',
        intensity : 'Intensity',
        isNotValid : 'is not valid',
        isRequired : 'is required',
        iUnderstand : 'I Understand',
        keepData : 'KEEP DATA',
        lastLoginTime : 'Last login time',
        left : 'Left',
        library : 'Library',
        libraryDisclaimer : 'We hope that the information obtained on this app will help you to be better able to participate with your health care providers in making informed decisions about your care. It is not a substitute for appropriate professional medical treatment or diagnosis. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on this site. Neither the Board of Regents nor its officers, agents or employees assume any legal liability or responsibility for the accuracy, completeness or usefulness of any information, apparatus, product or medical procedure described.',
        likeToDo : 'What would you like to do?',
        linkTitle : 'Link Title',
        linkUrl : 'Link URL',
        loading : 'Loading...',
        loggingOut : 'Logging Out',
        loginStatistics : 'Login Statistics',
        logout : 'Logout',
        manageMedications : 'Manage Medications',
        manageMeetings : 'Manage Meetings',
        maxHeartrate : 'Max Heart Rate',
        medication : 'Medication',
        medicationName : 'Medication Name',
        medications : 'Medications',
        meeting : 'Meeting',
        meetingName : 'Meeting Name',
        meetings : 'Meetings',
        meetingTimes : 'Meeting Times',
        meetingURL : 'Meeting URL',
        mind : 'Mind',
        mindfulnessIntervention : 'Mindfulness Intervention',
        myData : 'My Data',
        myPainScores : 'My Pain Scores',
        myPatients : 'My Patients',
        myStuff : 'My Stuff',
        myTools : 'My Tools',
        newEvent : 'New Event',
        next : 'Next',
        no : 'No',
        notCorrect : 'Not correct?',
        notes : 'Notes',
        numberOfLogins : 'Number of logins',
        painIntensity : 'Pain Intensity',
        pairDevice : 'Pair Device',
        patientData : 'Patient Data',
        phoneNumber : 'phone number',
        PHQ : 'PHQ',
        PHQScore : 'Anxiety Symptoms Score',
        pleaseEnterVerificationCode : 'Please enter verification code',
        pleaseTryAgain : 'please try again',
        plsEnterPhoneEmail : 'Please enter your email address or phone number',
        plsReadCarefully : 'Please Read Carefully',
        power : 'Power',
        prescribedValue : 'Prescribed Value',
        prescriptionVaries : 'Prescription varies (allows to enter values right before scheduling event)',
        previous : 'Previous',
        PROs : 'PROs',
        quickRelief : 'Quick Relief',
        quickReliefNow : 'Quick Relief Now',
        rateMyAnxiety : 'Rate My Anxiety',
        rateMyDepression : 'Rate My Depression',
        rateMyPain : 'Rate My Pain',
        recording : 'Recording...',
        removeFromBookmarks : 'Remove from Bookmarks',
        reset : 'RESET',
        resetProfileData : 'RESET Profile and Onboarding Data',
        right : 'Right',
        save : 'Save',
        saveNote : 'Save Note',
        saving : 'Saving',
        scheduleEvent : 'Schedule Event',
        seeStage : 'See Stage',
        selectDateToTake : 'Select Days To Take Medication',
        selectDaysForMeeting : 'Select Days For This Meeting',
        selectFavCat : 'Select the category you want to save to',
        selectLang : 'Select Language',
        sequences : 'Sequences',
        settings : 'Settings',
        settingsHasBeenSaved : 'Your settings have been saved.',
        signOut : 'Sign Out',
        speed : 'Speed',
        stage : 'Stage',
        stagingTool : 'Staging Tool',
        start : 'Start',
        startNoiseRecording : 'Start Noise Recording',
        startTakingDate : 'Start Taking Date',
        startVisitingDate : 'Start Visiting Date',
        stop : 'Stop',
        stopNoiseRecording : 'Stop Noise Recording',
        stopTakingDate : 'Stop Taking Date',
        stopVisitingDate : 'Stop Visiting Date',
        stuffFromMyCoach : 'Stuff From My Coach',
        survey : 'Survey',
        surveys : 'Surveys',
        syncHealthKitData : 'Sync Health Kit Data',
        tapResetToClear : 'Tap RESET to clear all the information entered into the app. This action cannot be undone. Tap KEEP DATA to go back.',
        targetHeartRate : 'Target Heart Rate',
        tellAboutMyPain : 'Tell about my pain',
        time : 'Time',
        timeSeconds : 'Time (seconds)',
        toReEnter : 'to re-enter',
        tryAgain : 'Try again',
        typeHere : 'Type here...',
        underConstruction : 'Under Construction',
        validatingCode : 'Validating code ...',
        verificationCodeRequired : 'Verification Code is required',
        viewAll: 'View All',
        welcome : 'Welcome, ^userName^!',
        weSentTo : 'We sent it to',
        weWillSendYouConfirmationCode : 'We will send you a confirmation code',
        workout : 'Workout',
        workouts : 'Workouts',
        wrongVerificationCode : 'Wrong verification code, please try again',
        yes : 'Yes',
        youHaveFitbit : 'You have Fitbit trackable exercise on this step. Tracking data will be synced automatically.',
        youHaveNotAddedBookmarks : 'You have not added any bookmarks yet',
        yourINSPIREJourney : 'Your INSPIRE Journey',
      } 
    },
    es : {
      language_name: 'Español',
      locale: 'es',
      strings: {
        APP_OVERVIEW : 'El propósito de esta aplicación es ayudar a los cuidadores a comprender las etapas funcionales de la demencia y de proveer enlaces a recursos útiles en cada etapa.',
        accept : 'Accept',
        addTime : 'Add Time',
        areYouSure : '¿Está seguro?',
        areYouSureYouWantToLogOut : '¿Esta seguro que quiere cerrar la sesión?',
        average : 'Promedio',
        beINSPIRED : 'INSPÍRATE',
        body : 'Cuerpo',
        bookmark : 'Marcar',
        bookmarks : 'Marcadores',
        bookmarkTag : 'Etiqueta de Marcador',
        books : 'Libros',
        bookSense : 'Sentido del Libro',
        cadence : 'Cadencia',
        cancel : 'Cancelar',
        center : 'Centro',
        click : 'clic',
        close : 'Cerrar',
        closing : 'Cerrando',
        community : 'Comunidad',
        communityWisdom : 'Sabiduría Comunitaria',
        completed : 'Completado',
        cognitiveIntervention : 'Intervención Cognitiva',
        contactMedicalProvider : 'Si cree que usted o alguien a quien cuida tiene una emergencia médica o de salud mental, llame al 911 o vaya a la sala de emergencias más cercana. No intente acceder a atención de emergencia a través de este sitio web.',
        continue : 'Continuar',
        continueYourINSPIREJourney : 'Continúa Con Tu Viaje INSPIRADOR',
        currLang : 'Idioma actual',
        credentialsNotValid : 'Las credenciales no son válidas',
        data : 'Datos',
        dataRecord : 'Registro de Datos',
        dataRecords : 'Registros de Datos',
        date : 'Fecha',
        decline : 'Declive',
        delete : 'Eliminar',
        deleteEvent : 'Eliminar Evento',
        deleteNote : 'Elimine Nota',
        description : 'Descripción',
        devicePaired : 'Dispositivo emparejado',
        disclaimer : 'Disclaimer',
        dismiss : 'Descartar',
        done : 'Finalizado',
        dose : 'Dosis',
        downloadFitbitApp : 'Descargue la aplicación Fitbit aquí',
        doYouWantToSaveTheResult : '¿Quiere guardar el resultado?',
        editEvent : 'Editar Evento',
        emailAddress : 'correo electrónico',
        emailPhoneRequired : 'Dirección de Correo Electrónico o Número de teléfono requerido',
        enableBookmark : 'Habilitar Marcador',
        enableFitbitDataSync : 'Habilitar la Sincronización de Datos de Fitbit',
        enjoymentOfLife : 'Disfrute de la Vida',
        enterAuthorizationCode : 'Ingrese código de verificación',
        enterCodeYouReceived : 'Ingrese el código que recibió',
        enterPhoneEmail : 'Ingrese teléfono o correo electrónico',        
        enterYour : 'Ingrese su',
        enterYourEmail : 'Ingrese su correo electrónico',
        eventStartTime : 'Hora de Inicio del Evento',
        findMoreProfessionalSupport : 'Encuentre Más Asistencia Profesional',
        fitbitDataSyncEnabled : 'Sincronización de Datos de Fitbit Habilitada',
        forgetDevice : 'Olvídate del Dispositivo',
        friend : 'Amigo',
        GAD : 'GAD',
        GADScore : 'Anxiety Symptoms Score',
        gear : 'Engranaje',
        generalActivity : 'Actividad General',
        generatingVerificationCode : 'Generando código de verificación',
        goBack : 'Regresar',
        goodDay : '¡Buen Día!',
        goodEvening : '¡Buenas noches!',
        goodMorning : '¡Buen día!',
        hasPrescribedParameters : 'Tiene Parámetros Prescritos',
        heartRate : 'Frecuencia Cardíaca',
        here : 'aquí',
        history : 'Historia',
        home: 'Inicio',
        imageAlignment : 'Alineación de la Imagen',
        imageAltText : 'Texto Alternativo de la Imagen',
        imageUrl : 'URL de la IMAGEN',
        inspireJourneyProgress : 'INSPIRE Journey Progress',
        intakeTimes : 'Tiempos de Admisión',
        intensity : 'Intensity',
        isNotValid : 'no es válido',
        isRequired : 'se requiere',
        iUnderstand : 'I Understand',
        keepData : 'CONSERVAR DATOS',
        lastLoginTime : 'Last login time',
        left : 'Izquierda',
        library : 'Biblioteca',
        libraryDisclaimer : 'We hope that the information obtained on this app will help you to be better able to participate with your health care providers in making informed decisions about your care. It is not a substitute for appropriate professional medical treatment or diagnosis. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on this site. Neither the Board of Regents nor its officers, agents or employees assume any legal liability or responsibility for the accuracy, completeness or usefulness of any information, apparatus, product or medical procedure described.',
        likeToDo : '¿Qué te gustaría hacer?',
        linkTitle : 'Título del Enlace',
        linkUrl : 'URL del ENLACE',
        loading : 'Cargando...',
        loggingOut : 'Cerrar sesión',
        loginStatistics : 'Login Statistics',
        logout : 'Cerrar Sesión',
        manageMedications : 'Administrar Medicamentos',
        manageMeetings : 'Administrar Reuniones',
        maxHeartrate : 'Frecuencia Cardíaca Máxima',
        medication : 'Medicación',
        medicationName : 'Nombre del Medicamento',
        medications : 'Medicamentos',
        meeting : 'Reunión',
        meetingName : 'Nombre de la Reunión',
        meetings : 'Reuniones',
        meetingTimes : 'Horarios de las Reuniones',
        meetingURL : 'URL de la REUNIÓN',
        mind : 'Mente',
        mindfulnessIntervention : 'Intervención de Atención Plena',
        myData : 'My Data',
        myPainScores : 'My Pain Scores',
        myPatients : 'My Patients',
        myStuff : 'Mis Cosas',
        myTools : 'Mis Herramientas',
        newEvent : 'Nuevo Evento',
        next : 'Siguiente',
        no : 'No',
        notCorrect : '¿No es correcto?',
        notes : 'Notas',
        numberOfLogins : 'Number of logins',
        painIntensity : 'Intensidad del Dolor',
        pairDevice : 'Dispositivo de Emparejamiento',
        patientData : 'Patient Data',
        phoneNumber : 'número de teléfono',
        PHQ : 'PHQ',
        PHQScore : 'Depressive Symptoms Score',
        pleaseEnterVerificationCode : 'Favor de ingresar su código de verificación',
        pleaseTryAgain : 'por favor, inténtelo de nuevo',
        plsEnterPhoneEmail : 'Por favor, introduzca su dirección de correo electrónico o número de teléfono',
        plsReadCarefully : 'Please Read Carefully',
        power : 'Potencia',
        prescribedValue : 'Valor Prescrito',
        prescriptionVaries : 'La prescripción varía (permite ingresar valores justo antes de programar el evento)',
        previous : 'Anterior',
        PROs : 'PROs',
        quickRelief : 'Alivio Rápido',
        quickReliefNow : 'Alivio Rápido Ahora',
        rateMyAnxiety : 'Rate My Anxiety',
        rateMyDepression : 'Rate My Depression',
        rateMyPain : 'Rate My Pain',
        recording : 'Grabación...',
        removeFromBookmarks : 'Elimine Marcador',
        reset : 'REINICIAR',
        resetProfileData : 'REINICIAR perfil y datos de incorporación',
        right : 'Lado',
        save : 'Guardar',
        saveNote : 'Guardar Nota',
        saving : 'Guardando',
        scheduleEvent : 'Programar Evento',
        seeStage : 'Ver Etapa',
        selectDateToTake : 'Seleccionar Días Para Tomar La Medicación',
        selectDaysForMeeting : 'Seleccione Los Días Para Esta Reunión',
        selectFavCat : 'Select the category you want to save to',
        selectLang : 'Seleccione lengua',
        sequences : 'Secuencias',
        settings : 'Configuraciones',
        settingsHasBeenSaved : 'Su configuración ha sido guardada.',
        signOut : 'Cerrar Sesión',
        speed : 'Velocidad',
        stage : 'Etapa',
        stagingTool : 'Evaluación de etapas',
        start : 'Inicio',
        startNoiseRecording : 'Iniciar Grabación de Ruido',
        startTakingDate : 'Fecha de Inicio de Toma',
        startVisitingDate : 'Fecha de Inicio de Visita',
        stop : 'Detener',
        stopNoiseRecording : 'Detener la Grabación de Ruido',
        stopTakingDate : 'Dejar de Tomar Fecha',
        stopVisitingDate : 'Dejar de Visitar la Fecha',
        stuffFromMyCoach : 'Cosas De Mi Entrenador',
        survey : 'Encuesta',
        surveys : 'Encuestas',
        syncHealthKitData : 'Sincronizar Datos de HealthKit',
        tapResetToClear : 'Toque REINICIAR para borrar toda la información ingresada en la aplicación. Esta acción no se puede deshacer. Toque CONSERVAR DATOS para volver.',
        targetHeartRate : 'Frecuencia Cardíaca Objetivo',
        tellAboutMyPain : 'Cuéntame sobre mi dolor',
        time : 'Tiempo',
        timeSeconds : 'Tiempo (segundos)',
        toReEnter : 'para volver a ingresar el código',
        tryAgain : 'Favor de intentar de nuevo.',
        typeHere : 'Escriba aquí...',
        underConstruction : 'Under Construction',
        validatingCode : 'Código de validación ...',
        verificationCodeRequired : 'Un código de verificación es requerido',
        viewAll: 'Ver Todo',
        welcome : 'Bienvenidas, ^userName^!',
        weSentTo : 'Lo enviamos a',
        weWillSendYouConfirmationCode : 'Te enviaremos un código de confirmación',
        workout : 'Entrenamiento',
        workouts : 'Entrenamientos',
        wrongVerificationCode : 'Código de verificación incorrecto. Favor de intentar de nuevo.',
        yes : 'Sí',
        youHaveFitbit : 'You have Fitbit trackable exercise on this step. Tracking data will be synced automatically.',
        youHaveNotAddedBookmarks : 'Todavía no ha agregado marcadores.',
        yourINSPIREJourney : 'Tu Viaje INSPIRADOR',
      } 
    }
  }

  locale : string;

  l: any;

  constructor(
    private http: HttpClient,
  ) {
    let locale = localStorage.getItem('ohn-locale');
    this.locale = locale ? locale : 'en';
    const langString = localStorage.getItem('ohn-locale-object');
    if (langString) {
      this.lang = JSON.parse(langString)
    } else {
      this.lang = this.backupLang;
      localStorage.setItem('ohn-locale-object', JSON.stringify(this.lang))
    }
    if (!this.lang[this.locale]) {
      this.locale = 'en'
    }
    this.l = this.lang[this.locale].strings;
    this.init();
    //console.log(JSON.stringify(this.lang));
  }

  init() {
    const langReq = this.http.get(API_URL+ '/localization/' + APP_SLUG)
    .pipe(
      map(res => {
        if (res[this.locale] && Object.keys(res[this.locale].strings).length > 0) {
          this.lang = res;
          this.l = this.lang[this.locale].strings;
          //this.l = this.backupLang[this.locale].strings;
          localStorage.setItem('ohn-locale-object', JSON.stringify(this.lang));
          this.languageLoader.next(true);
        }
      }),
      catchError(err => {
        console.log('Error loading localizations')
        return empty();
      })
    );
    langReq.subscribe()
  }

  getAvailableLocales(){
    let languages: any[] = [];
    const locales = Object.keys(this.lang);
    locales.forEach(l=>{
      languages.push({locale : l, name : this.lang[l].language_name})
    });
    return languages;
  }

  setCurrentLocale(locale: string) {
    this.locale = locale;
    this.l = this.lang[this.locale].strings;
    localStorage.setItem('ohn-locale', locale);
    this.languageChanger.next(locale);
  }

  g(slug: string){
    return this.l[slug]//this.lang[this.locale].strings[slug];
  }
}
