
  <!--<div class="ohn-panel-header">
    <h3>{{selectedDate | date: 'EEEE, MMMM d'}} Agenda</h3>
  </div>-->

    <ion-grid>
      <ion-row *ngFor="let event of selectedDateEvents" [ngClass]="{'agenda-row-completed': event.completed}" class="agenda-row" (click)="openEventModal(event)">
        <ion-col size="4" class="agenda-col-time agenda-{{event.controller}}">
          <h4>{{event.startTime | date:'shortTime'}}</h4>
          <span>{{event.endTime | date:'shortTime'}}</span>
        </ion-col>
        <ion-col class="agenda-col-event">
          <span>{{event.controller}}</span>
          <h3>{{event.title}}</h3>
          <p *ngIf="event.contentObject.url">
            <a href="{{event.contentObject.url}}" target="_blank">{{event.contentObject.url}}</a>
          </p>
          <!--<p *ngIf="event.controller == 'hkdatapoint'"><strong>[{{lS.g('dataRecord')}}]</strong></p>-->
        </ion-col>
      </ion-row>
    </ion-grid>



<ion-modal [isOpen]="isModalOpen">
  <ng-template>
<!--    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title>{{selectedEvent.title}}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="setOpen(false)">
            <ion-icon name="close" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
-->    
    <ion-content>
      <!--<app-agenda-event [calendarSlug]="calendarSlug" [user]="user" [event]="selectedEvent"></app-agenda-event>-->
      <app-agenda-event-view *ngIf="!selectedEvent.completed" [calendarSlug]="calendarSlug" [user]="user" [event]="selectedEvent" [inlineCommunicator]="inlineCommunicator"></app-agenda-event-view>
      <app-agenda-event-report *ngIf="selectedEvent.completed" [calendarSlug]="calendarSlug" [user]="user" [event]="selectedEvent"></app-agenda-event-report>
    </ion-content>
  </ng-template>
</ion-modal> 

