import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yes-no-input-element',
  templateUrl: './yes-no-input-element.component.html',
  styleUrls: ['./yes-no-input-element.component.scss'],
})
export class YesNoInputElementComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
