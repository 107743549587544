<!--<ion-header>
  <ion-toolbar>
    <ion-title>Printout</ion-title>
    <ion-buttons slot="end">
      <ion-item target="_system" href="{{fileURL}}">
        <ion-label>
            DOWNLOAD/PRINT AS PDF 
          </ion-label>
        </ion-item>
      <ion-button (click)="closeModal()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>-->
  <app-loading-animation *ngIf="loading.show" [aniType]="loading.type"></app-loading-animation>

  <div id="htmlContainer">
    <ng-container *ngIf="!loading.show">
      <div id="printout" style="font-family: sans-serif;">
        <h1 style="font-weight: bold;text-align: center;">Pain Management Plan</h1>
        <section *ngFor="let table of reportContent" style="border: 1px solid #ddd;">
          <header style="background-color: #6f6eb0;text-align: center;padding: 10px;">
            <h4 style="color:#fff;font-weight: bold;margin:0;">{{table.text}}</h4>
          </header>
          <ng-container [ngSwitch]="table.element_slug">
            <ng-container *ngSwitchCase="'pain_plan_goals_content'">
              <div style="display: flex;" *ngFor="let row of table.val.content">
                <div style="flex: 1;padding: 10px;border: 1px solid #ddd;">
                  <strong>{{row.text}}</strong><br>{{row.description}}
                </div>
              </div>  
            </ng-container>
            <ng-container *ngSwitchCase="'pain_plan_tools_content'"> 
              <div style="display: flex;" *ngFor="let row of table.val.content">
                <div style="flex: 1;padding: 10px;border: 1px solid #ddd;">
                  <strong>{{row.text}}</strong><br><a href="{{row.url}}" target="_blank">{{row.url}}</a>
                </div>
              </div>  
            </ng-container>
            <ng-container *ngSwitchCase="'pain_plan_care_team_content'">
              <div style="display: flex;">
                <div style="flex: 1;padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;">Doctors and Providers</div>
                <div style="flex: 1;padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;">Family and Friends</div>
                <div style="flex: 1;padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;">Emergency</div>
              </div>
              <div style="display:flex;">
                <div style="flex: 1;border: 1px solid #ddd;">
                  <div *ngFor="let row of table.val.content | filterBy:['category']:['doctors-and-providers-90939']" style="flex: 1;padding: 10px;border-bottom: 1px solid #ddd;">
                    <strong>{{row.text}}</strong><br><a href="tel:{{row.url}}" target="_blank">{{row.url}}</a><br>{{row.description || ' '}}<br>
                  </div>
                </div>
                <div style="flex: 1;border: 1px solid #ddd;">
                  <div *ngFor="let row of table.val.content | filterBy:['category']:['family-and-friends-32833']" style="flex: 1;padding: 10px;border-bottom: 1px solid #ddd;">
                    <strong>{{row.text}}</strong><br><a href="tel:{{row.url}}" target="_blank">{{row.url}}</a><br>{{row.description || ' '}}<br>
                  </div>
                </div>
                <div style="flex: 1;border: 1px solid #ddd;">
                  <div style="flex: 1;padding: 10px;border-bottom: 1px solid #ddd;">
                    <strong></strong><br><a href="tel:911" target="_blank">911</a><br>&nbsp;<br>
                  </div>
                  <div style="flex: 1;padding: 10px;border-bottom: 1px solid #ddd;">
                    <strong><strong>Suicide and Crisis Lifeline</strong></strong><br><a href="tel:988" target="_blank">988</a><br>&nbsp;<br>
                  </div>
                  <div *ngFor="let row of table.val.content | filterBy:['category']:['emergency-numbers-60225']" style="flex: 1;padding: 10px;border-bottom: 1px solid #ddd;">
                    <strong>{{row.text}}</strong><br><a href="tel:{{row.url}}" target="_blank">{{row.url}}</a><br>{{row.description || ' '}}<br>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'pain_plan_medications_content'">   
              <div style="display: flex;">
                <div style="flex: 1;padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;">Medication</div>
                <div style="flex: 1;padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;">When to take</div>
                <div style="flex: 1;padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;">How to take</div>
              </div>   
              <div style="display: flex;" *ngFor="let row of table.val.content">
                <div style="flex: 1;padding: 10px;border: 1px solid #ddd;">
                  <strong>{{row.text}}</strong>
                </div>
                <div style="flex: 1;padding: 10px;border: 1px solid #ddd;">
                  {{row.date}}
                </div>
                <div style="flex: 1;padding: 10px;border: 1px solid #ddd;">
                  {{row.description}}
                </div>
              </div>   
            </ng-container>
            <ng-container *ngSwitchCase="'pain_plan_flare_plan_content'">   
              <div style="display: flex;">
                <div style="flex: 1;padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;">Steps</div>
                <div style="flex: 1;padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;">Description</div>
              </div>   
              <div style="display: flex;" *ngFor="let row of table.val.content">
                <div style="flex: 1;padding: 10px;border: 1px solid #ddd;">
                  <strong>{{row.text}}</strong>
                </div>
                <div style="flex: 1;padding: 10px;border: 1px solid #ddd;">
                  {{row.description}}
                </div>
              </div>   
            </ng-container>
            <ng-container *ngSwitchCase="'pain_plan_other_content'">     
              <div style="display: flex;" *ngFor="let row of table.val.content">
                <div style="flex: 1;padding: 10px;border: 1px solid #ddd;">
                  {{row.text}}
                </div>
              </div>   
            </ng-container>
          </ng-container>
        </section>
<!--
        <table *ngFor="let table of reportContent" style="width: 100%;border: 1px solid #ddd;margin: 15px 0;border-collapse:collapse;">
          <thead>
            <th style="padding: 10px;color:#fff;background-color: #6f6eb0;text-align: center;" colspan="3">{{table.text}}</th>
          </thead>
          <tbody>
            <ng-container [ngSwitch]="table.element_slug">
              <ng-container *ngSwitchCase="'pain_plan_goals_content'">      
                <tr *ngFor="let row of table.val.content">
                  <td style="padding: 10px;border: 1px solid #ddd;width: 100%;"><strong>{{row.text}}</strong><br>{{row.description}}</td>
                </tr>
              </ng-container>
              <ng-container *ngSwitchCase="'pain_plan_tools_content'">      
                <tr *ngFor="let row of table.val.content">
                  <td style="padding: 10px;border: 1px solid #ddd;width: 100%;"><strong>{{row.text}}</strong><br><a href="{{row.url}}" target="_blank">{{row.url}}</a></td>
                </tr>
              </ng-container>
              <ng-container *ngSwitchCase="'pain_plan_care_team_content'">    
                <tr>
                  <td style="padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;width: 100%;">Doctors and Providers</td>
                  <td style="padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;width: 100%;">Family and Friends</td>
                  <td style="padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;width: 100%;">Emergency</td>
                </tr>
                <tr>
                  <td>
                    <table>
                      <tr *ngFor="let row of table.val.content | filterBy:['category']:['doctors-and-providers-90939']">
                        <td style="padding: 10px;border: 1px solid #ddd;width: 100%;">
                          <strong>{{row.text}}</strong><br><a href="tel:{{row.url}}" target="_blank">{{row.url}}</a><br>{{row.description}}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table>
                      <tr *ngFor="let row of table.val.content | filterBy:['category']:['family-and-friends-32833']" style="padding: 10px;border: 1px solid #ddd">
                        <td style="padding: 10px;">
                          <strong>{{row.text}}</strong><br><a href="tel:{{row.url}}" target="_blank">{{row.url}}</a><br>{{row.description}}
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table>
                      <tr *ngFor="let row of table.val.content | filterBy:['category']:['emergency-numbers-60225']" style="padding: 10px;border: 1px solid #ddd">
                        <td>
                          <strong>{{row.text}}</strong><br><a href="tel:{{row.url}}" target="_blank">{{row.url}}</a><br>{{row.description}}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngSwitchCase="'pain_plan_medications_content'">      
                <tr>
                  <td style="padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;width: 100%;">Medication</td>
                  <td style="padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;width: 100%;">When to take</td>
                  <td style="padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;width: 100%;">How to take</td>
                </tr>      
                <tr *ngFor="let row of table.val.content">
                  <td style="padding: 10px;border: 1px solid #ddd;width: 100%;">
                    <strong>{{row.text}}</strong>
                  </td>
                  <td style="padding: 10px;border: 1px solid #ddd;width: 100%;">{{row.date}}</td>
                  <td style="padding: 10px;border: 1px solid #ddd;width: 100%;">{{row.description}}</td>
                </tr>
              </ng-container>
              <ng-container *ngSwitchCase="'pain_plan_flare_plan_content'">      
                <tr>
                  <td style="padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;width: 100%;">Steps</td>
                  <td style="padding: 10px;background-color: #f5f5f5;font-weight: bold;border: 1px solid #ddd;width: 100%;">Description</td>
                </tr>      
                <tr *ngFor="let row of table.val.content">
                  <td style="padding: 10px;border: 1px solid #ddd;width: 100%;">
                    <strong>{{row.text}}</strong>
                  </td>
                  <td style="padding: 10px;border: 1px solid #ddd;width: 100%;">{{row.description}}</td>
                </tr>
              </ng-container>
              <ng-container *ngSwitchCase="'pain_plan_other_content'">  
                <tr *ngFor="let row of table.val.content">
                  <td style="padding: 10px;border: 1px solid #ddd;width: 100%;">
                    {{row.text}}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
-->
      </div>
    </ng-container>
  </div>

  <ion-button *ngIf="!loading.show" target="_system" href="{{fileURL}}" [disabled]="!fileURL" expand="block" class="ion-margin" mode="ios" fill="outline">
    <ion-icon name="print-outline" slot="start"></ion-icon>
    Download/Print as PDF 
  </ion-button>
<!--
</ion-content>
-->