import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { OhnApiService } from '../../api/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { OHNElement, OHNCalendarEvent, OHNUser, OHNLoading} from '../../models/ohn-instances';

@Component({
  selector: 'app-ohn-diary',
  templateUrl: './ohn-diary.component.html',
  styleUrls: ['./ohn-diary.component.scss'],
})
export class OhnDiaryComponent  implements OnInit {

  @Input() user: OHNUser;
  @Input() mini: boolean;

  loading: OHNLoading = new OHNLoading(true, 'triad');
  diary : OHNElement;
  selectedField : OHNElement;
  dataEditingOpened : boolean = false;
  fieldValue : any;
  saving : boolean = false;
  showDiaryModal: boolean = false;

  constructor(
    private ohnApi: OhnApiService,
  ) { }

  ngOnInit() {
    this.ohnApi.getElement('ohn_diary', 3).subscribe(diary => {
      this.diary = <OHNElement>diary;
      this.loading.show = false;
    })
  }

  openFieldEditingModal(field : OHNElement) {
    switch(field.controller) {
      case 'ohnBloodPressureController' :
        this.fieldValue = {
          ohn_bp_systolic : undefined,
          ohn_bp_diastolic : undefined
        };
        break;
      default : this.fieldValue = undefined;
    }
    this.selectedField = field;
    this.dataEditingOpened = true;
  }

  closeDataInputModal() {
    this.dataEditingOpened = false
  }

  saveData() {
    console.log(this.fieldValue);
    this.saving = true;
    this.ohnApi.setElementStateSc(this.selectedField.element_slug, {value : this.fieldValue}, this.user.smart_contract).subscribe(state => {
      const calendarEvent = {
        value : {
          controller: 'ohn_diary',
          startTime: new Date(),
          completed: true,
          title: this.selectedField.text,
          inner_element_slug: this.selectedField.element_slug,
          inner_element_state_id: state.id
        }
      }
      this.ohnApi.setElementStateSc('calendar_container', calendarEvent, this.user.smart_contract).subscribe(state => {
        console.log(state)
        this.saving = false
        this.closeDataInputModal()
      })
    })
    /*calendarHistory.push({
          smart_contract: this.user.smart_contract,
          value: {
            allDay: true,
            controller: "dataframe",
            startTime: r.value.time[0],
            completed: true,
            title: title,
            inner_element_slug: 'hk_' + field,
            inner_element_state_id: r.id
          }
        })*/
  }

}
